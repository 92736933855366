import {
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { arrayMoveImmutable } from 'array-move';
import fastCartesian from 'fast-cartesian';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { actionPermissions } from '../../../constants/actionPermissions';
import { WHITE } from '../../../constants/color';
import {
  GoodData,
  GoodsSkuAttr,
  GoodsSkuProductData,
  GoodsTypeAttributeData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import SpecTypesDropdown from '../common/SpecTypeDropdown';
import GoodSpecModal from './GoodSpecModal';
import FavActivitySection from '../../activity/common/FavActivitySection';

type GoodPriceProps = {
  goodInfo?: GoodData;
  callBack?: () => void;
  isCurrentEditing?: boolean;
  setIsCurrentEditing?: React.Dispatch<React.SetStateAction<boolean>>;
};

const GoodSpecs = ({
  goodInfo,
  callBack,
  isCurrentEditing,
  setIsCurrentEditing,
}: GoodPriceProps) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [loading, setLoading] = useState(false);
  const [specAttr, setSpecAttr] = useState<GoodsTypeAttributeData[]>([]);
  const [showAddAttribute, setShowAddAttribute] = useState<{
    [key: string]: boolean;
  }>({});
  const [attributeInput, setAttributeInput] = useState('');
  const [selectedSpec, setSelectedSpec] = useState(
    new Map<string, { [key: string]: string }[]>()
  );
  const [goodSkuProducts, setGoodSkuProducts] = useState<GoodsSkuProductData[]>(
    []
  );
  const [specNameList, setSpecNameList] = useState<{
    [key: string]: string;
  }>({});
  const [specAttrList, setSpecAttrList] = useState<{
    [key: string]: GoodsSkuAttr;
  }>({});
  const [showGoodSpecModal, setShowGoodSpecModal] = useState(false);
  const [editingAttr, setEditingAttr] = useState<
    GoodsSkuAttr & { specTypeName: string; specTypeId: string }
  >();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  useEffect(() => {
    if (isSubscribed.current && goodInfo && goodInfo.sku) {
      setSpecAttr(goodInfo.sku.specTypeAttr || []);
      let selectedSpec = new Map<string, { [key: string]: string }[]>();
      let selectedSpecName: { [key: string]: string } = {};
      let selectedAttr: { [key: string]: any } = {};
      for (let attr of goodInfo.sku.specTypeAttr) {
        selectedSpec.set(
          `${attr.specAttrId}`,
          attr.goodsSkuAttr.map((skuAttr) => {
            selectedAttr[`${skuAttr.goodsSkuAttrId}`] = skuAttr;
            return { [`${skuAttr.goodsSkuAttrId}`]: skuAttr.skuAttrValue };
          })
        );
        selectedSpecName[`${attr.specAttrId}`] = attr.specAttrName;
      }
      let products = cartesianProduct(Array.from(selectedSpec.values())).map(
        (a: any) => {
          if (goodInfo && goodInfo.sku && goodInfo.sku.goodsSkuProduct) {
            let foundAttr = goodInfo.sku.goodsSkuProduct.find(
              (p) =>
                Object.keys(a).every((key) =>
                  Object.keys(p.goodsSkuAttrIds).includes(key)
                ) &&
                Object.keys(p.goodsSkuAttrIds).every((key) =>
                  Object.keys(a).includes(key)
                )
            );

            if (foundAttr) {
              return foundAttr;
            } else {
              return { goodsSkuAttrIds: a };
            }
          }
          return { goodsSkuAttrIds: a };
        }
      );
      setSpecNameList(selectedSpecName);
      setSpecAttrList(selectedAttr);
      setSelectedSpec(selectedSpec);
      setGoodSkuProducts(products);
    }
  }, [goodInfo]);

  const getSpecTypeAttrs = (value?: number) => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken('goods/spec_type/attr/list', {
      params: { goodsSpecTypeId: value },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            let selectedSpecName: { [key: string]: string } = {};
            let selectedSpec = new Map<string, { [key: string]: string }[]>();
            response.data.list.forEach((data: GoodsTypeAttributeData) => {
              selectedSpecName[`${data.specAttrId}`] = data.specAttrName;
              selectedSpec.set(`${data.specAttrId}`, []);
            });
            setSelectedSpec(selectedSpec);
            setSpecNameList((prev) => ({ ...prev, ...selectedSpecName }));
            setSpecAttr(response.data.list);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  const SortableItem = SortableElement(
    ({ item, specId }: { item: any; specId: string }) => (
      <div style={{ cursor: 'grab', marginTop: 8, marginRight: 8 }}>
        <Tag style={{ margin: 0 }}>
          <Space>
            {item && !!Object.values(item).length && Object.values(item)[0]}
            <Tooltip
              title={t('goods.add/editGood.uploadImage')}
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            >
              <Button
                type="text"
                size="small"
                icon={<UploadOutlined />}
                onClick={() => {
                  let keys = Object.keys(item);
                  if (keys.length && keys[0]) {
                    setEditingAttr({
                      ...specAttrList[keys[0]],
                      skuAttrValue: specAttrList[keys[0]]
                        ? specAttrList[keys[0]].skuAttrValue
                        : item[keys[0]],
                      specTypeId: specId,
                      specTypeName: specNameList[specId],
                    });
                  } else {
                    setEditingAttr(undefined);
                  }
                  setShowGoodSpecModal(true);
                }}
              />
            </Tooltip>
          </Space>
        </Tag>
      </div>
    )
  );

  const SortableList = SortableContainer(
    ({ items, specId }: { items: any; specId: string }) => (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
        {items.map((item: string, index: number) => (
          <SortableItem item={item} index={index} key={index} specId={specId} />
        ))}
      </div>
    )
  );

  const handleEditing = () => {
    if (editing) {
      form
        .validateFields()
        .then((values) => {
          if (isSubscribed.current) setLoading(true);
          let modifiedSpecAttr: any = {};
          let attrIndex: any = {};
          selectedSpec.forEach((value, key) => {
            modifiedSpecAttr['p' + key] = value.map((obj, index) => {
              attrIndex['p' + Object.keys(obj)[0]] = { ['p' + key]: index };
              let attrId = Object.keys(obj)[0];
              let modified: any = {
                goodsSkuAttrId: attrId,
                skuAttrValue: obj[attrId],
                attrThumbPic:
                  specAttrList[attrId] && specAttrList[attrId].attrThumbPic
                    ? specAttrList[attrId].attrThumbPic
                    : '',
                attrLargePic:
                  specAttrList[attrId] && specAttrList[attrId].attrLargePic
                    ? specAttrList[attrId].attrLargePic
                    : '',
                attrOriginalPic:
                  specAttrList[attrId] && specAttrList[attrId].attrOriginalPic
                    ? specAttrList[attrId].attrOriginalPic
                    : '',
                isDefaultAttr:
                  specAttrList[attrId] && specAttrList[attrId].isDefaultAttr
                    ? specAttrList[attrId].isDefaultAttr
                    : false,
                sortOrder: index,
              };

              if (isNaN(modified.goodsSkuAttrId)) {
                delete modified.goodsSkuAttrId;
              } else {
                modified.goodsSkuAttrId = parseInt(modified.goodsSkuAttrId);
              }
              return modified;
            });
          });

          let modifiedSkuProduct = goodSkuProducts.map((product) => {
            let aIndex: any = {};
            let { goodsId, goodsSkuAttrIds, ...rest } = product;
            Object.keys(goodsSkuAttrIds).forEach((id) => {
              aIndex = { ...aIndex, ...attrIndex[id] };
            });
            return {
              ...rest,
              attrIndex: aIndex,
              productPrice: rest.productPrice || 0,
              productNumber: rest.productNumber || 0,
              productWarnNumber: rest.productWarnNumber || 1,
              oldProductNumber: rest.productId
                ? goodInfo &&
                  goodInfo.sku &&
                  goodInfo.sku.goodsSkuProduct &&
                  goodInfo.sku.goodsSkuProduct.length &&
                  goodInfo.sku.goodsSkuProduct.find(
                    (p) => p.productId === rest.productId
                  ) &&
                  goodInfo.sku.goodsSkuProduct.find(
                    (p) => p.productId === rest.productId
                  )?.productNumber
                : undefined,
            };
          });

          postDataWithAuthToken('goods/edit', {
            goodsId: goodInfo && goodInfo.goodsId,
            sku: {
              goodsSpecTypeId:
                values.goodsSpecTypeId && values.goodsSpecTypeId.value
                  ? values.goodsSpecTypeId.value
                  : 0,
              specTypeAttr: modifiedSpecAttr,
              skuProduct: modifiedSkuProduct,
            },
          })
            .then((response) => {
              if (response && response.goodStatus) {
                alertMessage('success', t('goods.alerts.stockEdited'));
                handleCancelEditing();
                if (callBack) callBack();

                 // 有促销活动提示
                if(response.data && response.data.favActivityList){
                  Modal.warning({
                    width: 800,
                    title: t('goods.add/editGood.goodActivityTitle'),
                    content: (
                      <FavActivitySection
                        favActivityList={response.data.favActivityList}
                      />
                    ),
                    okText: t('general.ok'),
                    maskClosable: false,
                  });
                }
              } else {
                alertMessage(
                  'error',
                  response?.msg || t('general.noResponse'),
                  response?.data || undefined
                );
              }
              if (isSubscribed.current) setLoading(false);
            })
            .catch((err) => {
              if (isSubscribed.current) setLoading(false);
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    } else {
      if (isSubscribed.current) {
        if (isCurrentEditing) {
          alertMessage('warning', t('order.alerts.saveWarning'));
        } else {
          setEditing(true);
          if (setIsCurrentEditing) setIsCurrentEditing(true);
        }
      }
    }
  };

  const handleCancelEditing = () => {
    if (isSubscribed.current) {
      if (goodInfo) {
        form.resetFields();
        setSpecAttr((goodInfo.sku && goodInfo.sku.specTypeAttr) || []);

        let selectedSpec = new Map<string, { [key: string]: string }[]>();
        let selectedSpecName: { [key: string]: string } = {};
        let selectedAttr: { [key: string]: any } = {};
        if (goodInfo.sku) {
          for (let attr of goodInfo.sku.specTypeAttr) {
            selectedSpec.set(
              `${attr.specAttrId}`,
              attr.goodsSkuAttr.map((skuAttr) => {
                selectedAttr[`${skuAttr.goodsSkuAttrId}`] = skuAttr;
                return { [`${skuAttr.goodsSkuAttrId}`]: skuAttr.skuAttrValue };
              })
            );
            selectedSpecName[`${attr.specAttrId}`] = attr.specAttrName;
          }
        }
        let products = cartesianProduct(Array.from(selectedSpec.values())).map(
          (a: any) => {
            if (goodInfo && goodInfo.sku && goodInfo.sku.goodsSkuProduct) {
              let foundAttr = goodInfo.sku.goodsSkuProduct.find(
                (p) =>
                  Object.keys(a).every((key) =>
                    Object.keys(p.goodsSkuAttrIds).includes(key)
                  ) &&
                  Object.keys(p.goodsSkuAttrIds).every((key) =>
                    Object.keys(a).includes(key)
                  )
              );

              if (foundAttr) {
                return foundAttr;
              } else {
                return { goodsSkuAttrIds: a };
              }
            }
            return { goodsSkuAttrIds: a };
          }
        );
        setSpecNameList(selectedSpecName);
        setSpecAttrList(selectedAttr);
        setSelectedSpec(selectedSpec);
        setGoodSkuProducts(products);
      }
      setEditing(false);
      if (setIsCurrentEditing) setIsCurrentEditing(false);
    }
  };

  /*
   * Cartesian Product (笛卡儿积)
   */
  const cartesianProduct = (arrays: { [key: string]: string }[][]) => {
    let filteredArray = arrays
      .filter((arr) => arr.length > 0)
      .map((array) => {
        return array.map((element) => ({
          ['p' + Object.keys(element)[0]]: Object.values(element)[0],
        }));
      });

    return fastCartesian(filteredArray).map((array) => {
      let obj = {};
      array.forEach((e) => {
        obj = { ...obj, ...e };
      });
      return obj;
    });
  };

  const columns: ColumnsType<GoodsSkuProductData> = [
    {
      title: t('goods.add/editGood.specTypeAttr'),
      width: 120,
      render: (record: GoodsSkuProductData) =>
        record.goodsSkuAttrIds
          ? Object.values(record.goodsSkuAttrIds).join(', ')
          : '',
    },
    {
      title: (
        <Space>
          {t('goods.goodsListColumns.shopPrice')}
          <Tooltip title={t('goods.add/editGood.specPriceRemark')}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'productPrice',
      width: 120,
      render: (value: number, record: GoodsSkuProductData, index: number) =>
        editing ? (
          <InputNumber
            type="number"
            min={0}
            defaultValue={value || 0}
            onChange={(value) => {
              setGoodSkuProducts((prev) => {
                let products = prev.slice();
                const currentIndex = pageSize * (page - 1) + index;
                products[currentIndex] = {
                  ...products[currentIndex],
                  productPrice: value || 0,
                };
                return products;
              });
            }}
          />
        ) : (
          value
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsNumber'),
      dataIndex: 'productNumber',
      width: 120,
      render: (value: number, record: GoodsSkuProductData, index: number) =>
        editing ? (
          <InputNumber
            type="number"
            min={0}
            defaultValue={value || 0}
            onChange={(value) => {
              setGoodSkuProducts((prev) => {
                let products = prev.slice();
                const currentIndex = pageSize * (page - 1) + index;
                products[currentIndex] = {
                  ...products[currentIndex],
                  productNumber: value || 0,
                };
                return products;
              });
            }}
          />
        ) : (
          value
        ),
    },
    {
      title: t('goods.add/editGood.warnNumber'),
      dataIndex: 'productWarnNumber',
      width: 120,
      render: (value: number, record: GoodsSkuProductData, index: number) =>
        editing ? (
          <InputNumber
            type="number"
            min={0}
            defaultValue={value || 1}
            onChange={(value) => {
              setGoodSkuProducts((prev) => {
                let products = prev.slice();
                const currentIndex = pageSize * (page - 1) + index;
                products[currentIndex] = {
                  ...products[currentIndex],
                  productWarnNumber: value || 1,
                };
                return products;
              });
            }}
          />
        ) : (
          value
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsSn'),
      dataIndex: 'productSn',
      width: 120,
      render: (value: string, record: GoodsSkuProductData, index: number) =>
        editing ? (
          <Input
            defaultValue={value}
            onChange={(e) => {
              setGoodSkuProducts((prev) => {
                let products = prev.slice();
                const currentIndex = pageSize * (page - 1) + index;
                products[currentIndex] = {
                  ...products[currentIndex],
                  productSn: e.target.value,
                };
                return products;
              });
            }}
          />
        ) : (
          value
        ),
    },
    {
      title: t('goods.goodsListColumns.barcode'),
      dataIndex: 'barCode',
      width: 120,
      render: (value: string, record: GoodsSkuProductData, index: number) =>
        editing ? (
          <Input
            defaultValue={value}
            onChange={(e) => {
              setGoodSkuProducts((prev) => {
                let products = prev.slice();
                const currentIndex = pageSize * (page - 1) + index;
                products[currentIndex] = {
                  ...products[currentIndex],
                  barCode: e.target.value,
                };
                return products;
              });
            }}
          />
        ) : (
          value
        ),
    },
  ];

  return goodInfo ? (
    <Spin spinning={loading}>
      <Form
        form={form}
        initialValues={
          goodInfo
            ? {
                goodsSpecTypeId:
                  goodInfo.sku && goodInfo.sku.specType
                    ? {
                        value: goodInfo.sku.specType.goodsSpecTypeId,
                        label: goodInfo.sku.specType.typeName,
                      }
                    : {
                        value: 0,
                        label: t('general.pleaseSelect'),
                      },
              }
            : {}
        }
      >
        <Row gutter={[24, 8]}>
          <Col span={16}>
            <Space>
              <Typography.Text strong style={{ fontSize: 16 }}>
                {t('goods.add/editGood.spec')}
              </Typography.Text>
              <Tooltip title={t('goods.add/editGood.specStockRemark')}>
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>
          </Col>
          {!goodInfo.isRelatedGoods && (
            <Col span={8}>
              <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {editing ? (
                  <Space>
                    <Button onClick={() => handleCancelEditing()}>
                      {t('goods.add/editGood.cancel')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        if (
                          hasPermission(actionPermissions.goodGroup.goodManage)
                        )
                          handleEditing();
                      }}
                    >
                      {t('goods.add/editGood.ok')}
                    </Button>
                  </Space>
                ) : (
                  <Tooltip
                    title={t('goods.add/editGood.edit')}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentNode as HTMLElement
                    }
                  >
                    <Button
                      type="text"
                      onClick={() => {
                        if (
                          hasPermission(actionPermissions.goodGroup.goodManage)
                        )
                          handleEditing();
                      }}
                      icon={<EditOutlined />}
                    />
                  </Tooltip>
                )}
              </Space>
            </Col>
          )}
          {editing && (
            <Col span={24}>
              <Form.Item
                name="goodsSpecTypeId"
                label={t('goods.add/editGood.specType')}
                style={{ marginBottom: 0 }}
                // rules={[{ required: true }]}
              >
                <SpecTypesDropdown
                  labelInValue={true}
                  initialValue={
                    goodInfo && goodInfo.sku && goodInfo.sku.specType
                      ? {
                          value: goodInfo.sku.specType.goodsSpecTypeId,
                          label: goodInfo.sku.specType.typeName,
                        }
                      : undefined
                  }
                  onChange={(value) => {
                    if (!value) {
                      setSelectedSpec(new Map());
                      setGoodSkuProducts([]);
                      setSpecAttr([]);
                      return;
                    }

                    if (
                      goodInfo &&
                      goodInfo.sku &&
                      goodInfo.sku.specType &&
                      goodInfo.sku.specType.goodsSpecTypeId === value.value
                    ) {
                      let selectedAttr = new Map<
                        string,
                        { [key: string]: string }[]
                      >();
                      for (let attr of goodInfo.sku.specTypeAttr) {
                        selectedAttr.set(
                          `${attr.specAttrId}`,
                          attr.goodsSkuAttr.map((skuAttr) => ({
                            [`${skuAttr.goodsSkuAttrId}`]: skuAttr.skuAttrValue,
                          }))
                        );
                      }
                      setSelectedSpec(selectedAttr);
                      setGoodSkuProducts(goodInfo.sku.goodsSkuProduct || []);
                      setSpecAttr(goodInfo.sku.specTypeAttr || []);
                    } else {
                      setSelectedSpec(new Map());
                      setGoodSkuProducts([]);
                      getSpecTypeAttrs(value.value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {editing && (
            <Col span={24}>
              <Form.Item
                label={t('goods.add/editGood.specTypeAttr')}
                style={{ marginBottom: 0 }}
              >
                {specAttr.map((item, itemIndex) => (
                  <Row key={itemIndex} style={{ marginTop: 5 }}>
                    <Col span={24}>
                      <Typography.Text>{item.specAttrName}:</Typography.Text>
                    </Col>
                    <Col>
                      <Space wrap>
                        {item.isInputAttrFromList ? (
                          item.inputAttrValues &&
                          !!item.inputAttrValues.length &&
                          item.inputAttrValues.map((value, index) => (
                            <Tag key={index} style={{ margin: 0 }}>
                              <Space>
                                <Checkbox
                                  checked={
                                    selectedSpec.has(`${item.specAttrId}`) &&
                                    (
                                      selectedSpec.get(`${item.specAttrId}`) ||
                                      []
                                    ).find((a) => Object.values(a)[0] === value)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let attr = new Map<
                                      string,
                                      { [key: string]: string }[]
                                    >();
                                    if (e.target.checked) {
                                      let attrId = `${item.specAttrId}_${value}`;
                                      if (item.goodsSkuAttr) {
                                        let foundAttr = item.goodsSkuAttr.find(
                                          (a) => a.skuAttrValue === value
                                        );
                                        if (foundAttr) {
                                          attrId = `${foundAttr.goodsSkuAttrId}`;
                                        }
                                      }

                                      attr = new Map(selectedSpec).set(
                                        `${item.specAttrId}`,
                                        [
                                          ...(selectedSpec.get(
                                            `${item.specAttrId}`
                                          ) || []),
                                          { [attrId]: value },
                                        ]
                                      );
                                    } else {
                                      attr = new Map(selectedSpec).set(
                                        `${item.specAttrId}`,
                                        (
                                          selectedSpec.get(
                                            `${item.specAttrId}`
                                          ) || []
                                        ).filter(
                                          (a) => Object.values(a)[0] !== value
                                        )
                                      );
                                    }

                                    let products = cartesianProduct(
                                      Array.from(attr.values())
                                    ).map((a: any) => {
                                      if (
                                        goodInfo &&
                                        goodInfo.sku &&
                                        goodInfo.sku.goodsSkuProduct
                                      ) {
                                        let foundAttr =
                                          goodInfo.sku.goodsSkuProduct.find(
                                            (p) =>
                                              Object.keys(a).every((key) =>
                                                Object.keys(
                                                  p.goodsSkuAttrIds
                                                ).includes(key)
                                              ) &&
                                              Object.keys(
                                                p.goodsSkuAttrIds
                                              ).every((key) =>
                                                Object.keys(a).includes(key)
                                              )
                                          );

                                        if (foundAttr) {
                                          return foundAttr;
                                        } else {
                                          return { goodsSkuAttrIds: a };
                                        }
                                      }
                                      return { goodsSkuAttrIds: a };
                                    });
                                    setSelectedSpec(attr);
                                    setGoodSkuProducts(products);
                                  }}
                                />
                                {value}
                              </Space>
                            </Tag>
                          ))
                        ) : (
                          <>
                            {item.goodsSkuAttr &&
                              !!item.goodsSkuAttr.length &&
                              item.goodsSkuAttr.map((goodAttr, index) => (
                                <Tag
                                  style={{ margin: 0 }}
                                  key={index}
                                  visible
                                  onClose={() => {
                                    setSpecAttr((prev) => {
                                      let attrs = prev.slice();
                                      attrs[itemIndex] = {
                                        ...item,
                                        goodsSkuAttr: [
                                          ...item.goodsSkuAttr.slice(0, index),
                                          ...item.goodsSkuAttr.slice(index + 1),
                                        ],
                                      };
                                      return attrs;
                                    });

                                    let selectedAttr = new Map(
                                      selectedSpec
                                    ).set(
                                      `${item.specAttrId}`,
                                      (
                                        selectedSpec.get(
                                          `${item.specAttrId}`
                                        ) || []
                                      ).filter(
                                        (a) =>
                                          Object.keys(a)[0] !==
                                          `${goodAttr.goodsSkuAttrId}`
                                      )
                                    );

                                    let products = cartesianProduct(
                                      Array.from(selectedAttr.values())
                                    ).map((a: any) => {
                                      if (
                                        goodInfo &&
                                        goodInfo.sku &&
                                        goodInfo.sku.goodsSkuProduct
                                      ) {
                                        let foundAttr =
                                          goodInfo.sku.goodsSkuProduct.find(
                                            (p) =>
                                              Object.keys(a).every((key) =>
                                                Object.keys(
                                                  p.goodsSkuAttrIds
                                                ).includes(key)
                                              ) &&
                                              Object.keys(
                                                p.goodsSkuAttrIds
                                              ).every((key) =>
                                                Object.keys(a).includes(key)
                                              )
                                          );

                                        if (foundAttr) {
                                          return foundAttr;
                                        } else {
                                          return { goodsSkuAttrIds: a };
                                        }
                                      }
                                      return { goodsSkuAttrIds: a };
                                    });
                                    setSelectedSpec(selectedAttr);
                                    setGoodSkuProducts(products);
                                  }}
                                  closable={
                                    editing && !item.isInputAttrFromList
                                  }
                                >
                                  <Space>
                                    <Checkbox
                                      checked={
                                        selectedSpec.has(
                                          `${item.specAttrId}`
                                        ) &&
                                        (
                                          selectedSpec.get(
                                            `${item.specAttrId}`
                                          ) || []
                                        ).find(
                                          (a) =>
                                            Object.keys(a)[0] ===
                                            `${goodAttr.goodsSkuAttrId}`
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        let attr = new Map<
                                          string,
                                          { [key: string]: string }[]
                                        >();
                                        if (e.target.checked) {
                                          attr = new Map(selectedSpec).set(
                                            `${item.specAttrId}`,
                                            [
                                              ...(selectedSpec.get(
                                                `${item.specAttrId}`
                                              ) || []),
                                              {
                                                [`${goodAttr.goodsSkuAttrId}`]:
                                                  goodAttr.skuAttrValue,
                                              },
                                            ]
                                          );
                                        } else {
                                          attr = new Map(selectedSpec).set(
                                            `${item.specAttrId}`,
                                            (
                                              selectedSpec.get(
                                                `${item.specAttrId}`
                                              ) || []
                                            ).filter(
                                              (a) =>
                                                Object.keys(a)[0] !==
                                                `${goodAttr.goodsSkuAttrId}`
                                            )
                                          );
                                        }

                                        let products = cartesianProduct(
                                          Array.from(attr.values())
                                        ).map((a: any) => {
                                          if (
                                            goodInfo &&
                                            goodInfo.sku &&
                                            goodInfo.sku.goodsSkuProduct
                                          ) {
                                            let foundAttr =
                                              goodInfo.sku.goodsSkuProduct.find(
                                                (p) =>
                                                  Object.keys(a).every((key) =>
                                                    Object.keys(
                                                      p.goodsSkuAttrIds
                                                    ).includes(key)
                                                  ) &&
                                                  Object.keys(
                                                    p.goodsSkuAttrIds
                                                  ).every((key) =>
                                                    Object.keys(a).includes(key)
                                                  )
                                              );

                                            if (foundAttr) {
                                              return foundAttr;
                                            } else {
                                              return { goodsSkuAttrIds: a };
                                            }
                                          }
                                          return { goodsSkuAttrIds: a };
                                        });
                                        setSelectedSpec(attr);
                                        setGoodSkuProducts(products);
                                      }}
                                    />
                                    {goodAttr.skuAttrValue}
                                  </Space>
                                </Tag>
                              ))}
                            <Tag
                              style={{
                                borderStyle: 'dashed',
                                background: WHITE,
                                margin: 0,
                              }}
                              onClick={() =>
                                setShowAddAttribute({
                                  [`${item.specAttrId}`]: true,
                                })
                              }
                            >
                              {showAddAttribute[`${item.specAttrId}`] ? (
                                <Input
                                  size="small"
                                  style={{ width: 60, height: 18 }}
                                  autoFocus
                                  onChange={(e) => {
                                    setAttributeInput(e.target.value);
                                  }}
                                  onBlur={() => {
                                    if (attributeInput) {
                                      setSpecAttr((prev) => {
                                        let attrs = prev.slice();
                                        let goodsSkuAttr: GoodsSkuAttr[] = [];
                                        if (item.goodsSkuAttr) {
                                          let foundAttr =
                                            item.goodsSkuAttr.find(
                                              (a) =>
                                                a.skuAttrValue ===
                                                attributeInput
                                            );
                                          if (foundAttr) {
                                            goodsSkuAttr = [
                                              ...item.goodsSkuAttr,
                                            ];
                                          } else {
                                            let foundType =
                                              goodInfo.sku &&
                                              goodInfo.sku.specTypeAttr &&
                                              goodInfo.sku.specTypeAttr.find(
                                                (a) =>
                                                  a.specAttrId ===
                                                  item.specAttrId
                                              );
                                            if (
                                              foundType &&
                                              foundType.goodsSkuAttr
                                            ) {
                                              let foundAttr =
                                                foundType.goodsSkuAttr.find(
                                                  (a) =>
                                                    a.skuAttrValue ===
                                                    attributeInput
                                                );
                                              if (foundAttr) {
                                                goodsSkuAttr = [
                                                  ...item.goodsSkuAttr,
                                                  foundAttr,
                                                ];
                                                setSpecAttrList((prev) => ({
                                                  ...prev,
                                                  [`${item.specAttrId}`]: {
                                                    goodsSkuAttrId: `${item.specAttrId}`,
                                                    skuAttrValue:
                                                      attributeInput,
                                                  },
                                                }));
                                              } else {
                                                goodsSkuAttr = [
                                                  ...item.goodsSkuAttr,
                                                  {
                                                    goodsSkuAttrId: `${item.specAttrId}_${attributeInput}`,
                                                    skuAttrValue:
                                                      attributeInput,
                                                  },
                                                ];
                                                setSpecAttrList((prev) => ({
                                                  ...prev,
                                                  [`${item.specAttrId}_${attributeInput}`]:
                                                    {
                                                      goodsSkuAttrId: `${item.specAttrId}_${attributeInput}`,
                                                      skuAttrValue:
                                                        attributeInput,
                                                    },
                                                }));
                                              }
                                            } else {
                                              goodsSkuAttr = [
                                                ...item.goodsSkuAttr,
                                                {
                                                  goodsSkuAttrId: `${item.specAttrId}_${attributeInput}`,
                                                  skuAttrValue: attributeInput,
                                                },
                                              ];
                                              setSpecAttrList((prev) => ({
                                                ...prev,
                                                [`${item.specAttrId}_${attributeInput}`]:
                                                  {
                                                    goodsSkuAttrId: `${item.specAttrId}_${attributeInput}`,
                                                    skuAttrValue:
                                                      attributeInput,
                                                  },
                                              }));
                                            }
                                          }
                                        } else {
                                          goodsSkuAttr = [
                                            {
                                              goodsSkuAttrId: `${item.specAttrId}_${attributeInput}`,
                                              skuAttrValue: attributeInput,
                                            },
                                          ];
                                          setSpecAttrList((prev) => ({
                                            ...prev,
                                            [`${item.specAttrId}_${attributeInput}`]:
                                              {
                                                goodsSkuAttrId: `${item.specAttrId}_${attributeInput}`,
                                                skuAttrValue: attributeInput,
                                              },
                                          }));
                                        }
                                        attrs[itemIndex] = {
                                          ...item,
                                          goodsSkuAttr: goodsSkuAttr,
                                        };
                                        return attrs;
                                      });
                                    }
                                    setAttributeInput('');
                                    setShowAddAttribute({
                                      [`${item.specAttrId}`]: false,
                                    });
                                  }}
                                />
                              ) : (
                                <Space>
                                  <PlusOutlined />
                                  {t('goods.add/editGood.newAttribute')}
                                </Space>
                              )}
                            </Tag>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                ))}
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Table
              size="small"
              dataSource={goodSkuProducts}
              columns={columns}
              rowKey={(record) => Object.keys(record.goodsSkuAttrIds).join('|')}
              pagination={{
                pageSize: pageSize,
                hideOnSinglePage: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                // showSizeChanger: true,
                onChange: (p, pSize) => {
                  setPage(p);
                  setPageSize(pSize);
                },
                current: page,
              }}
              scroll={{ y: 600, x: 1200 }}
            />
          </Col>
          {editing &&
            !!Array.from(selectedSpec.values()).length &&
            Array.from(selectedSpec.values()).some((value) => value.length) && (
              <Col span={24}>
                <Form.Item
                  label={t('goods.goodsListColumns.sortOrder')}
                  style={{ marginBottom: 0 }}
                >
                  <Space direction="vertical" style={{ marginTop: 5 }}>
                    {Array.from(selectedSpec.keys()).map(
                      (specId, index) =>
                        !!(selectedSpec.get(`${specId}`) || []).length && (
                          <Row key={index}>
                            <Col span={24}>
                              <Typography.Text>
                                {specNameList[`${specId}`]}:
                              </Typography.Text>
                            </Col>
                            <Col span={24}>
                              <SortableList
                                specId={`${specId}`}
                                items={selectedSpec.get(`${specId}`) || []}
                                distance={1}
                                axis="xy"
                                onSortEnd={({ oldIndex, newIndex }) => {
                                  setSelectedSpec((prev) => {
                                    let attr = new Map(prev);
                                    if (attr.has(`${specId}`))
                                      attr.set(
                                        `${specId}`,
                                        arrayMoveImmutable(
                                          attr.get(`${specId}`) || [],
                                          oldIndex,
                                          newIndex
                                        )
                                      );
                                    return attr;
                                  });
                                }}
                              />
                            </Col>
                          </Row>
                        )
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
        </Row>
      </Form>
      <GoodSpecModal
        visible={showGoodSpecModal}
        setVisible={setShowGoodSpecModal}
        goodInfo={goodInfo}
        attrInfo={editingAttr}
        updateAttrInfo={(value: {
          [key: string]: GoodsSkuAttr & {
            specTypeName: string;
            specTypeId: string;
          };
        }) => {
          setSpecAttrList((prev) => ({ ...prev, ...value }));
        }}
      />
    </Spin>
  ) : (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('goods.add/editGood.spec')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Empty />
      </Col>
    </Row>
  );
};

export default GoodSpecs;
