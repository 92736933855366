import { useTranslation } from 'react-i18next';
import { FavActivityData } from '../../../types';
import { Col, Row, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

type FavActivitySectionProps = {
  favActivityList: FavActivityData[];
};

const FavActivitySection = ({ favActivityList }: FavActivitySectionProps) => {
  const { t } = useTranslation();

  const columns: ColumnsType<FavActivityData> = [
    {
      title: t('activity.favActivityColumnList.actId'),
      dataIndex: 'actId',
      key: 'actId',
      width: 70,
    },
    {
      title: t('activity.favActivityColumnList.actType.title'),
      dataIndex: 'actType',
      key: 'actType',
      width: 120,
      render: (text: string) =>
        t(`activity.favActivityColumnList.actType.${text}`),
    },
    {
      title: t('activity.favActivityColumnList.actName'),
      dataIndex: 'actName',
      key: 'actName',
      width: 160,
    },
    {
      title: t('activity.favActivityColumnList.minAmount'),
      dataIndex: 'minAmount',
      key: 'minAmount',
      width: 100,
    },
    {
      title: t('activity.favActivityColumnList.maxAmount'),
      dataIndex: 'maxAmount',
      key: 'maxAmount',
      width: 100,
    },

    {
      title: t('activity.favActivityColumnList.activityStatus.title'),
      dataIndex: 'activityStatus',
      key: 'activityStatus',
      width: 120,
      render: (text: string, record: FavActivityData) =>
        record.activityStatus && (
          <Tag color={record.activityStatus.tagColor}>
            {record.activityStatus.description}
          </Tag>
        ),
    },
    {
      title: t('activity.favActivityColumnList.reviewStatus'),
      dataIndex: 'reviewStatus',
      key: 'reviewStatus',
      width: 120,
      render: (text: string, record: FavActivityData) =>
        record.reviewStatus && (
          <Tag color={record.reviewStatus.tagColor}>
            {record.reviewStatus.description}
          </Tag>
        ),
    },
  ];

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t('goods.add/editGood.goodActivity')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Table
          scroll={{ x: 'max-content' }}
          columns={columns}
          dataSource={favActivityList}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default FavActivitySection;
