import {
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionPermissions } from '../../../constants/actionPermissions';
import { GoodData } from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../../utils/axiosRequest';
import { hasPermission } from '../../../utils/hasPermission';
import GoodPendingStock from './GoodPendingStock';
import GoodSpecs from './GoodSpecs';
import FavActivitySection from '../../activity/common/FavActivitySection';

type GoodStockModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  goodInfo?: GoodData;
  refresh?: () => void;
};

const GoodStockModal = ({
  visible,
  setVisible,
  goodInfo,
  refresh,
}: GoodStockModalProps) => {
  const { t } = useTranslation();
  const [goodInfoSkuOnly, setGoodInfoSkuOnly] = useState<GoodData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [form] = Form.useForm();

  const getGoodSkuInfo = useCallback(() => {
    setIsLoading(true);
    getDataWithAuthToken('goods/detail', {
      params: {
        goodsId: goodInfo && goodInfo.goodsId,
        skuOnly: true,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          setGoodInfoSkuOnly(response.data);
          form.setFieldsValue({ ...response.data });
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [goodInfo, t, form]);

  const onClose = () => {
    setGoodInfoSkuOnly(undefined);
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      getGoodSkuInfo();
    }
  }, [visible, getGoodSkuInfo]);

  const handleOkOnStock = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        postDataWithAuthToken('goods/edit', {
          goodsId: goodInfoSkuOnly && goodInfoSkuOnly.goodsId,
          oldGoodsNumber: goodInfoSkuOnly && goodInfoSkuOnly.goodsNumber,
          goodsNumber: values.goodsNumber,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              setIsLoading(false);
              alertMessage('success', t('goods.alerts.stockEdited'));
              refresh && refresh();
              onClose();
              
              // 有促销活动提示
              if(response.data && response.data.favActivityList){
                Modal.warning({
                  width: 800,
                  title: t('goods.add/editGood.goodActivityTitle'),
                  content: (
                    <FavActivitySection
                      favActivityList={response.data.favActivityList}
                    />
                  ),
                  okText: t('general.ok'),
                  maskClosable: false,
                });
              }
            } else {
              setIsLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      title={t('goods.add/editGood.editStock')}
      visible={visible}
      onCancel={onClose}
      footer={null}
      width="90%"
      style={{ maxWidth: 800 }}
      destroyOnClose
    >
      <Spin spinning={isLoading}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Form form={form}>
              <Form.Item
                label={
                  <Typography.Text strong style={{ fontSize: 16 }}>
                    {t('goods.goodsListColumns.goodsNumber')}
                  </Typography.Text>
                }
                style={{ marginBottom: 0 }}
              >
                {!isLoading &&
                goodInfoSkuOnly &&
                !goodInfoSkuOnly.sku &&
                !goodInfoSkuOnly.isRelatedGoods ? (
                  <Space size="middle">
                    <Form.Item
                      name="goodsNumber"
                      noStyle
                      rules={[
                        {
                          required:
                            !isLoading &&
                            goodInfoSkuOnly &&
                            !goodInfoSkuOnly.sku &&
                            !goodInfoSkuOnly.isRelatedGoods,
                          message: t('general.inputError.pleaseInputAmount'),
                        },
                      ]}
                    >
                      <InputNumber
                        disabled={
                          !hasPermission(actionPermissions.goodGroup.goodManage)
                        }
                        type="number"
                        min={0}
                        onPressEnter={(e) => {
                          handleOkOnStock();
                        }}
                        autoFocus
                      />
                    </Form.Item>
                    <Space>
                      <Typography.Text>
                        {t('goods.add/editGood.incGoodsNum')}:
                      </Typography.Text>
                      <InputNumber
                        disabled={
                          !hasPermission(actionPermissions.goodGroup.goodManage)
                        }
                        type="number"
                        onPressEnter={(e) => {
                          // 更新库存数值
                          const event = e.target as HTMLButtonElement;
                          const editNum = event.value ? event.value : 0;
                          const goodsNumber = form.getFieldValue('goodsNumber');
                          form.setFieldsValue({
                            goodsNumber:
                              (goodsNumber ? goodsNumber : 0) + Number(editNum),
                          });
                        }}
                      />
                    </Space>
                  </Space>
                ) : (
                  <>{goodInfoSkuOnly && goodInfoSkuOnly.goodsNumber}</>
                )}
              </Form.Item>
            </Form>
          </Col>
          {goodInfo && (
            <Col span={24}>
              <GoodPendingStock visible={true} goodsId={goodInfo.goodsId} />
            </Col>
          )}
          <Col span={24}>
            <GoodSpecs
              goodInfo={goodInfoSkuOnly}
              callBack={() => {
                refresh && refresh();
                onClose();
              }}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default GoodStockModal;
