import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Grid,
  Image,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SortOrder, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import GoodModal from '../../components/goods/GoodModal';
import GoodReviewModal from '../../components/goods/GoodReviewModal';
import BrandDropdown from '../../components/goods/common/BrandDropdown';
import CategoryDropdown from '../../components/goods/common/CategoryDropdown';
import DepartmentDropdown from '../../components/goods/common/DepartmentDropdown';
import SupplierDropdown from '../../components/goods/common/SupplierDropdown';
import GoodStockModal from '../../components/goods/goodDetail/GoodStockModal';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { GREEN1, RED1, RED2 } from '../../constants/color';
import { REVIEW_STATUS } from '../../constants/generalConstants';
import { dashboardRoute } from '../../constants/pathname';
import { FALLBACK_IMG } from '../../constants/styles';
import {
  DATE_FORMAT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTab } from '../../hooks/useTab';
import {
  BasicEnumInfoType,
  FontSizeType,
  GoodData,
  GoodEnums,
  GoodNumberRule,
  SellerData,
} from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import {
  isAndroid,
  isIOS,
  mergeParamsToString,
  tableScrollToTop,
} from '../../utils/helperFunction';
import FavActivitySection from '../../components/activity/common/FavActivitySection';

const { useBreakpoint } = Grid;
const { TabPane } = Tabs;
const GoodsList = () => {
  //General Components
  const [isEmptyInput, setIsEmptyInput] = useState<boolean>(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const { t } = useTranslation();
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const screens = useBreakpoint();
  const [isLoading, setIsLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [morePopoverVis, setMorePopoverVis] = useState<{
    [key: number]: boolean;
  }>({});
  const formRef = useRef(null);
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [showGoodModal, setShowGoodModal] = useState(false);
  const [form] = Form.useForm();
  const isSubscribed = useRef(true);
  const [showStockModal, setShowStockModal] = useState(false);
  const [goodsTags, setGoodsTags] = useState<BasicEnumInfoType[]>([]);
  const { addTab } = useTab();
  const [navType, setNavType] = useState('NORMAL');
  //Data Components
  const [goodsEnum, setGoodsEnum] = useState<GoodEnums>();
  const [editingGood, setEditingGood] = useState<GoodData>();
  const [batchAdvance, setBatchAdvance] = useState<{
    sellerId?: number;
    catId?: number;
    departmentId?: number;
    brandId?: number;
    eventTag?: string;
    isOnSale?: string;
    reviewStatus?: string;
    goodsTag?: string;
  }>({});
  const [goodsData, setGoodsData] = useState<GoodData[]>([]);
  //Table Components
  const [editValidator, setEditValidator] = useState<{
    data: GoodData;
    rowIndex: any;
    colIndex: any;
  }>();
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(null);
  const [sortValue, setSortValue] = useState<React.Key>('');
  const [isSeller, setIsSeller] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const columnKeys = [
    'goodsId',
    `shopName|brandName|supplierName`,
    'goodsName|goodsSn|barcode|supplierSku',
    'goodsUnit',
    'goodsNumber',
    'salesVolume',
    'shopPrice|isMember|memberPrice',
    'isWeightGoods|goodsWeight|estimateShopPrice',
    'eventTag',
    'reviewStatus',
    'sortOrder',
    'expireDate',
    'lastUpdate',
    'addTime',
    'action',
    'sellerNote',
    'goodsNumberRule',
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    columnKeys.filter((key) => key !== 'reviewStatus' && key !== 'addTime')
  );
  const [selectedRows, setSelectedRows] = useState<GoodData[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchAdvance, setSearchAdvance] = useState<{ [key: string]: any }>(
    {}
  );
  //Text Components
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const ellipsis = useState(true);
  const [weightValues, setWeightValues] = useState<{
    isWeightGoods?: boolean;
    goodsWeight?: number;
  }>();
  const [goodNumberValues, setGoodNumberRuleValues] = useState<{
    sign?: string | null;
    number?: number | null;
  }>();

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  // Generate export config url
  const getExportUrl = (params: { [key: string]: any }) => {
    return mergeParamsToString('goods/export/excel?', params);
  };

  const goodBriefPopover = (record: GoodData) => (
    <Space direction="vertical">
      <Typography.Text>
        {t('goods.goodsListColumns.keywords')}: {record.keywords}
      </Typography.Text>
      <Typography.Text>
        {t('goods.goodsListColumns.goodsBrief')}: {record.goodsBrief}
      </Typography.Text>
    </Space>
  );

  const editInputCell = (
    record: GoodData,
    colIndex: string,
    index: number,
    isRequired: boolean = true
  ) => {
    return editValidator &&
      editValidator?.rowIndex === index &&
      editValidator.colIndex === colIndex ? (
      <Space direction="vertical">
        <Input
          autoFocus
          defaultValue={`${record[colIndex as keyof GoodData]}`}
          style={{ width: '100%' }}
          onBlur={() => {
            setIsEmptyInput(false);
            setEditValidator(undefined);
          }}
          onChange={(e) => {
            let target = e.target as HTMLInputElement;
            // must to be empty
            if (isRequired) {
              !target.value ? setIsEmptyInput(true) : setIsEmptyInput(false);
            }
          }}
          onPressEnter={(e) => {
            let target = e.target as HTMLInputElement;
            if (isRequired) {
              target.value &&
                hasPermission(actionPermissions.goodGroup.goodManage) &&
                updateGood(colIndex, target.value, record);
            } else {
              hasPermission(actionPermissions.goodGroup.goodManage) &&
                updateGood(colIndex, target.value, record);
            }
          }}
        />
        <Typography.Text
          style={{
            fontSize: fontSize,
            color: RED1,
            display: isEmptyInput ? '' : 'none',
          }}
        >
          {t('general.inputError.empty')}
        </Typography.Text>
      </Space>
    ) : (
      <Typography.Text
        style={{
          fontSize: fontSize,
          cursor: 'pointer',
        }}
        onClick={() => {
          setEditValidator({
            data: record,
            rowIndex: index,
            colIndex: colIndex,
          });
        }}
      >
        {record[colIndex as keyof GoodData]}
      </Typography.Text>
    );
  };

  const columns: ColumnsType<GoodData> = [
    {
      title: setFont(t('goods.goodsListColumns.goodsId'), fontSize),
      key: 'goodsId',
      dataIndex: 'goodsId',
      fixed: screens.lg ? 'left' : undefined,
      width: 70,
      sorter: true,
      render: (text: string, record: GoodData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          onClick={() =>
            addTab(
              '',
              `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
            )
          }
          disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(
        t('goods.goodsListColumns.seller/brand/supplier'),
        fontSize,
        `${t('goods.goodsListColumns.shopName')}|${t(
          'goods.goodsListColumns.brandName'
        )}|${t('goods.goodsListColumns.supplier')}`
      ),
      key: 'shopName|brandName|supplierName',
      width: 120,
      render: (value: SellerData, record: GoodData) => (
        <Space direction="vertical">
          <Typography.Text
            style={
              ellipsis
                ? {
                    display: record.seller ? '' : 'none',
                    width: 120,
                    fontSize: fontSize,
                  }
                : undefined
            }
            ellipsis={
              ellipsis
                ? { tooltip: record.seller ? record.seller.shopName : '' }
                : false
            }
          >
            {`${t('goods.goodsListColumns.seller')}: ${
              record.seller ? record.seller.shopName : ''
            }`}
          </Typography.Text>
          <Typography.Text
            style={
              ellipsis
                ? {
                    display: record.brand ? '' : 'none',
                    width: 120,
                    fontSize: fontSize,
                  }
                : undefined
            }
            ellipsis={
              ellipsis
                ? { tooltip: record.brand ? record.brand.brandName : '' }
                : false
            }
          >
            {`${t('goods.goodsListColumns.brand')}: ${
              record.brand ? record.brand.brandName : ''
            }`}
          </Typography.Text>
          <Typography.Text
            style={
              ellipsis
                ? {
                    display: record.supplier ? '' : 'none',
                    width: 120,
                    fontSize: fontSize,
                  }
                : undefined
            }
            ellipsis={
              ellipsis
                ? {
                    tooltip: record.supplier
                      ? record.supplier.supplierName
                      : '',
                  }
                : false
            }
          >
            {`${t('goods.goodsListColumns.supplier')}: ${
              record.supplier ? record.supplier.supplierName : ''
            }`}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: setFont(
        t('goods.goodsListColumns.goodsName'),
        fontSize,
        `${t('goods.goodsListColumns.goodsName')}|${t(
          'goods.goodsListColumns.goodsSn'
        )}|${t('goods.goodsListColumns.barcode')}|${t(
          'goods.goodsListColumns.supplierSku'
        )}`
      ),
      key: 'goodsName|goodsSn|barcode|supplierSku',
      dataIndex: 'goodsName|goodsSn|barcode|supplierSku',
      width: 300,
      sorter: true,
      render: (value: string, record: GoodData, index) => {
        return (
          <Space size="middle">
            {record.promotePercentage ? (
              <Badge.Ribbon
                text={`${record.promotePercentage}%`}
                color={RED1}
                placement="start"
              >
                <Image
                  src={record.largePic}
                  fallback={FALLBACK_IMG}
                  width={90}
                  preview={{ src: record.largePic }}
                />
              </Badge.Ribbon>
            ) : (
              <Image
                src={record.largePic}
                fallback={FALLBACK_IMG}
                width={90}
                preview={{ src: record.largePic }}
              />
            )}
            <Space direction="vertical">
              {editValidator?.rowIndex === index &&
              editValidator.colIndex === 'goodsName' ? (
                <Space direction="vertical">
                  <Input
                    autoFocus
                    defaultValue={record.goodsName}
                    style={{ width: '100%' }}
                    onBlur={() => {
                      setIsEmptyInput(false);
                      setEditValidator(undefined);
                    }}
                    onChange={(e) => {
                      let target = e.target as HTMLInputElement;
                      !target.value
                        ? setIsEmptyInput(true)
                        : setIsEmptyInput(false);
                    }}
                    onPressEnter={(e) => {
                      let target = e.target as HTMLInputElement;
                      target.value &&
                        hasPermission(actionPermissions.goodGroup.goodManage) &&
                        updateGood('goodsName', target.value, record);
                    }}
                  />
                  <Typography.Text
                    style={{
                      fontSize: fontSize,
                      color: RED1,
                      display: isEmptyInput ? '' : 'none',
                    }}
                  >
                    {t('general.inputError.empty')}
                  </Typography.Text>
                </Space>
              ) : (
                <Popover
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                  content={goodBriefPopover(record)}
                >
                  <Typography.Text
                    style={{
                      fontSize: fontSize,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setEditValidator({
                        data: record,
                        rowIndex: index,
                        colIndex: 'goodsName',
                      });
                    }}
                  >
                    {record.goodsName}
                  </Typography.Text>
                </Popover>
              )}
              <Space direction="vertical" wrap>
                {record.isRelatedGoods && (
                  <Tag color="#708B74" style={{ fontSize: fontSize }}>
                    {t('goods.goodsListColumns.isRelatedGood')}
                  </Tag>
                )}
                {record.activityTagList &&
                  record.activityTagList.map((tag) => (
                    <Tag
                      color={tag.tagColor}
                      key={tag.code}
                      style={{ fontSize: fontSize }}
                    >
                      {tag.description}
                    </Tag>
                  ))}
              </Space>
              <Space>
                <Typography.Text>
                  {t('goods.goodsListColumns.goodsSn')}:
                </Typography.Text>
                {editInputCell(record, 'goodsSn', index, true)}
              </Space>
              {record.barcode && (
                <Space>
                  <Typography.Text>
                    {t('goods.goodsListColumns.barcode')}:
                  </Typography.Text>
                  {editInputCell(record, 'barcode', index, false)}
                </Space>
              )}
              {record.supplierSku && (
                <Space>
                  <Typography.Text>
                    {t('goods.goodsListColumns.supplierSku')}:
                  </Typography.Text>
                  {editInputCell(record, 'supplierSku', index, false)}
                </Space>
              )}
            </Space>
          </Space>
        );
      },
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsUnit'), fontSize),
      key: 'goodsUnit',
      dataIndex: 'goodsUnit',
      width: 100,
      render: (value: string, record: GoodData, index) =>
        editInputCell(record, 'goodsUnit', index, true),
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsNumberRule'), fontSize),
      key: 'goodsNumberRule',
      dataIndex: 'goodsNumberRule',
      width: 130,
      render: (rule: GoodNumberRule, record: GoodData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'goodsNumberRule' ? (
          <Space direction="vertical">
            <Select
              defaultValue={rule && rule.sign}
              style={{ minWidth: 100 }}
              allowClear
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              showSearch={false}
              filterOption={false}
              onChange={(value) => {
                setGoodNumberRuleValues((prev) => ({
                  ...prev,
                  sign: value || null,
                }));
                if (!value) setIsEmptyInput(false);
              }}
            >
              {goodsEnum &&
                goodsEnum.goodsNumberRuleSign.length > 0 &&
                goodsEnum.goodsNumberRuleSign.map((rule) => (
                  <Select.Option key={rule.code} value={rule.code}>
                    {rule.description}
                  </Select.Option>
                ))}
            </Select>
            <InputNumber
              type="number"
              min={0}
              defaultValue={rule && rule.number}
              style={{ minWidth: 100 }}
              onChange={(value) => {
                setGoodNumberRuleValues((prev) => ({
                  ...prev,
                  number: value || undefined,
                }));
                if (goodNumberValues && goodNumberValues.sign && !value) {
                  setIsEmptyInput(true);
                } else {
                  setIsEmptyInput(false);
                }
              }}
            />
            <Typography.Text
              style={{
                fontSize: fontSize,
                color: RED1,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
            <Space>
              <Button
                size="small"
                onClick={() => {
                  if (
                    goodNumberValues &&
                    goodNumberValues.sign &&
                    !goodNumberValues.number
                  ) {
                    setIsEmptyInput(true);
                  } else {
                    updateGood('goodsNumberRule', goodNumberValues, record);
                  }
                }}
              >
                {t('general.ok')}
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setEditValidator(undefined);
                  setGoodNumberRuleValues(undefined);
                  setIsEmptyInput(false);
                }}
              >
                {t('general.cancel')}
              </Button>
            </Space>
          </Space>
        ) : (
          <Space>
            <Typography.Text
              style={{
                fontSize: fontSize,
              }}
            >
              {rule && rule.description} {rule && rule.number}
            </Typography.Text>
            <Button
              disabled={!hasPermission(actionPermissions.goodGroup.goodManage)}
              size="small"
              onClick={() => {
                setEditValidator({
                  data: record,
                  rowIndex: index,
                  colIndex: 'goodsNumberRule',
                });
                setGoodNumberRuleValues({
                  sign: rule ? rule.sign : null,
                  number: rule ? rule.number : undefined,
                });
              }}
              icon={<EditOutlined />}
            />
          </Space>
        ),
    },
    {
      title: setFont(
        t('goods.goodsListColumns.shopPrice'),
        fontSize,
        `${t('goods.goodsListColumns.shopPrice')}|${t(
          'goods.goodsListColumns.isMember'
        )}|${t('goods.goodsListColumns.memberPrice')}`
      ),
      key: 'shopPrice|isMember|memberPrice',
      dataIndex: 'shopPrice|isMember|memberPrice',
      width: 80,
      sorter: true,
      render: (value: number, record: GoodData, index) => (
        <Space direction="vertical">
          {editInputCell(record, 'shopPrice', index, true)}
          {record.promotePrice && (
            <Typography.Text style={{ fontSize: fontSize, color: RED1 }}>
              {record.promotePrice}
            </Typography.Text>
          )}
          {record.memberPrice && (
            <Typography.Text style={{ fontSize: fontSize, color: RED2 }}>
              VIP {record.memberPrice}
            </Typography.Text>
          )}
        </Space>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.goodsNumber'), fontSize),
      key: 'goodsNumber',
      dataIndex: 'goodsNumber',
      width: 80,
      sorter: true,
      render: (text: string, record: GoodData) =>
        record.hasGoodsSkuAttr ? (
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={() => {
              setEditingGood(record);
              setShowStockModal(true);
            }}
          />
        ) : (
          <Button
            style={{
              padding: 0,
              fontSize: fontSize,
            }}
            type="text"
            onClick={() => {
              setEditingGood(record);
              setShowStockModal(true);
            }}
          >
            {text}
          </Button>
        ),
    },
    {
      title: setFont(
        t('goods.goodsListColumns.weight/price'),
        fontSize,
        `${t('goods.goodsListColumns.isWeightGoods')}|${t(
          'goods.goodsListColumns.goodsWeight'
        )}|${t('goods.goodsListColumns.estimateShopPrice')}`
      ),
      key: 'isWeightGoods|goodsWeight|estimateShopPrice',
      width: 180,
      render: (text: string, record: GoodData, index) => (
        <Space style={{ width: '100%' }} direction="vertical" size={4}>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(
              `${t('goods.goodsListColumns.isWeightGoods')}: `,
              fontSize
            )}
            {editValidator?.rowIndex === index &&
            editValidator.colIndex === 'isWeightGoods|goodsWeight' ? (
              <Switch
                defaultChecked={editValidator.data.isWeightGoods}
                onChange={(checked) => {
                  setWeightValues((prev) => ({
                    ...prev,
                    isWeightGoods: checked,
                  }));
                }}
              />
            ) : record.isWeightGoods ? (
              <CheckOutlined style={{ color: GREEN1, fontSize: fontSize }} />
            ) : (
              <CloseOutlined style={{ color: RED1, fontSize: fontSize }} />
            )}
          </Space>

          {editValidator?.rowIndex === index &&
          editValidator.colIndex === 'isWeightGoods|goodsWeight' ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ paddingRight: 5 }}>
                {setFont(
                  `${t('goods.goodsListColumns.goodsWeight')}: `,
                  fontSize
                )}
              </div>

              <div>
                <InputNumber
                  // style={{ width: 50 }}
                  type="number"
                  defaultValue={parseFloat(record.goodsWeight)}
                  min={0}
                  checked={editValidator.data.isWeightGoods}
                  onChange={(value) => {
                    setWeightValues((prev) => ({
                      ...prev,
                      goodsWeight: value || 0,
                    }));
                  }}
                />
              </div>
            </div>
          ) : record.isWeightGoods ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {setFont(
                `${t('goods.goodsListColumns.goodsWeight')}: `,
                fontSize
              )}
              {setFont(record.goodsWeight, fontSize)}
            </div>
          ) : undefined}
          {record.isWeightGoods ? (
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
              {setFont(
                `${t('goods.goodsListColumns.estimateShopPrice')}: `,
                fontSize
              )}
              {record.goodsWeight !== '' &&
                setFont(record.estimateShopPrice || '', fontSize)}
            </Space>
          ) : undefined}
          <Space
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {editValidator?.rowIndex === index &&
            editValidator.colIndex === 'isWeightGoods|goodsWeight' ? (
              <Space>
                <Button
                  disabled={
                    !hasPermission(actionPermissions.goodGroup.goodManage)
                  }
                  size="small"
                  onClick={() => {
                    if (
                      weightValues &&
                      weightValues.isWeightGoods === true &&
                      weightValues.goodsWeight !== undefined &&
                      weightValues.goodsWeight === 0
                    ) {
                      alertMessage(
                        'warning',
                        t('goods.alerts.missingWeightWarning')
                      );
                    } else if (
                      weightValues &&
                      weightValues.isWeightGoods !== undefined &&
                      weightValues.goodsWeight !== undefined
                    ) {
                      updateGood('goodsWeight', weightValues, record);
                    }
                  }}
                >
                  {t('general.ok')}
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setEditValidator(undefined);
                    setWeightValues(undefined);
                  }}
                >
                  {t('general.cancel')}
                </Button>
              </Space>
            ) : (
              <Button
                size="small"
                onClick={() => {
                  setWeightValues({
                    isWeightGoods: record.isWeightGoods,
                    goodsWeight: parseFloat(record.goodsWeight),
                  });
                  setEditValidator({
                    data: record,
                    rowIndex: index,
                    colIndex: 'isWeightGoods|goodsWeight',
                  });
                }}
                icon={<EditOutlined />}
              />
            )}
          </Space>
        </Space>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.eventTag'), fontSize),
      key: 'eventTag',
      width: 130,
      render: (record: GoodData) => (
        <Space direction="vertical" size={4}>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isBest')}:`, fontSize)}
            <Switch
              checked={record.isBest}
              disabled={!hasPermission(actionPermissions.goodGroup.goodManage)}
              onChange={(checked) => updateGood('isBest', checked, record)}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isNew')}:`, fontSize)}
            <Switch
              disabled={!hasPermission(actionPermissions.goodGroup.goodManage)}
              checked={record.isNew}
              onChange={(checked) => updateGood('isNew', checked, record)}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isHot')}:`, fontSize)}
            <Switch
              checked={record.isHot}
              disabled={!hasPermission(actionPermissions.goodGroup.goodManage)}
              onChange={(checked) => updateGood('isHot', checked, record)}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isMember')}:`, fontSize)}
            <Switch
              className="isMember"
              checked={record.isMember}
              disabled={!hasPermission(actionPermissions.goodGroup.goodManage)}
              onChange={(checked) => updateGood('isMember', checked, record)}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isPresale')}:`, fontSize)}
            <Switch
              className="isPresale"
              checked={record.isPresale}
              disabled={!hasPermission(actionPermissions.goodGroup.goodManage)}
              onChange={(checked) => updateGood('isPresale', checked, record)}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Space>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            {setFont(`${t('goods.goodsListColumns.isOnSale')}:`, fontSize)}
            <Switch
              className="isOnSale"
              checked={record.isOnSale}
              disabled={!hasPermission(actionPermissions.goodGroup.goodManage)}
              onChange={(checked) => updateGood('isOnSale', checked, record)}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Space>
        </Space>
      ),
    },
    {
      title: setFont(t('goods.goodsListColumns.salesVolume'), fontSize),
      key: 'salesVolume',
      dataIndex: 'salesVolume',
      width: 80,
      sorter: true,
      render: (text) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.goodsListColumns.sortOrder'), fontSize),
      key: 'sortOrder',
      dataIndex: 'sortOrder',
      width: 80,
      sorter: true,
      render: (value: number, record: GoodData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'sortOrder' ? (
          <Space direction="vertical">
            <InputNumber
              autoFocus
              defaultValue={value}
              min={0}
              max={50000}
              placeholder="0-50000"
              style={{ width: '100%', fontSize: fontSize }}
              onBlur={() => {
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onChange={(input) =>
                !input ? setIsEmptyInput(true) : setIsEmptyInput(false)
              }
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                target.value &&
                  hasPermission(actionPermissions.goodGroup.goodManage) &&
                  updateGood('sortOrder', target.value, record);
              }}
            />
            <Typography.Text
              type="danger"
              style={{
                fontSize: fontSize,
                display: isEmptyInput ? '' : 'none',
              }}
            >
              {t('general.inputError.empty')}
            </Typography.Text>
          </Space>
        ) : (
          <Button
            style={{ padding: 0, fontSize: fontSize }}
            type="text"
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'sortOrder',
              });
            }}
          >
            {value}
          </Button>
        ),
    },
    {
      title: setFont(t('goods.goodsListColumns.expireDate'), fontSize),
      key: 'expireDate',
      dataIndex: 'expireDate',
      width: 160,
      sorter: true,
      render: (value: string, record: GoodData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'expireDate' ? (
          <Space direction="vertical">
            {!isIOS() && !isAndroid() ? (
              <Input
                type="date"
                pattern="\d{4}-\d{2}-\d{2}"
                max="9999-12-31"
                autoFocus
                defaultValue={value === '0000-00-00' ? '' : value}
                style={{ width: '100%', fontSize: fontSize }}
                onClick={(e) => {
                  e.preventDefault();
                }}
                onBlur={(e) => {
                  // Desktop version: close window
                  if (screens.lg) {
                    setIsEmptyInput(false);
                    setEditValidator(undefined);
                  } else {
                    // Mobile: update date (cause onPressEnter not working)
                    let target = e.target as HTMLInputElement;
                    if (target.value !== value) {
                      if (hasPermission(actionPermissions.goodGroup.goodManage))
                        updateGood('expireDate', target.value, record);
                    }
                    setIsEmptyInput(false);
                    setEditValidator(undefined);
                  }
                }}
                onPressEnter={(e) => {
                  let target = e.target as HTMLInputElement;
                  if (hasPermission(actionPermissions.goodGroup.goodManage))
                    updateGood('expireDate', target.value, record);
                }}
              />
            ) : (
              /** Alternative option for picking date */
              <DatePicker
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                onChange={(date, dateString) => {
                  if (dateString === '') {
                    updateGood('expireDate', '', record);
                  }
                }}
                autoFocus
                defaultValue={
                  value === '0000-00-00' ? undefined : moment(value)
                }
                format={DATE_FORMAT}
                style={{ width: '100%', fontSize: fontSize }}
                showNow={false}
                showToday={false}
                allowClear
                onOk={(date) => {
                  if (moment(date).isValid()) {
                    updateGood(
                      'expireDate',
                      moment(date).format(DATE_FORMAT),
                      record
                    );
                  }
                }}
                onBlur={(e) => {
                  if (
                    moment(e.target.value).isValid() &&
                    e.target.value !== value
                  ) {
                    updateGood(
                      'expireDate',
                      moment(e.target.value).format(DATE_FORMAT),
                      record
                    );
                  }
                  setIsEmptyInput(false);
                  setEditValidator(undefined);
                }}
              />
            )}
          </Space>
        ) : (
          <Button
            style={{ padding: 0, fontSize: fontSize }}
            type="text"
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'expireDate',
              });
            }}
          >
            {value}
          </Button>
        ),
    },
    {
      title: setFont(t('goods.add/editGood.sellerNote'), fontSize),
      key: 'sellerNote',
      dataIndex: 'sellerNote',
      width: 100,
      render: (value: string, record: GoodData, index) =>
        editValidator?.rowIndex === index &&
        editValidator.colIndex === 'sellerNote' ? (
          <Space direction="vertical">
            <Input.TextArea
              autoFocus
              defaultValue={value}
              style={{ width: '100%', fontSize: fontSize }}
              onBlur={() => {
                setIsEmptyInput(false);
                setEditValidator(undefined);
              }}
              onPressEnter={(e) => {
                let target = e.target as HTMLInputElement;
                if (hasPermission(actionPermissions.goodGroup.goodManage))
                  updateGood('sellerNote', target.value, record);
              }}
              autoSize={{ minRows: 1 }}
            />
          </Space>
        ) : value ? (
          <Typography.Text
            style={{
              fontSize: fontSize,
              cursor: 'pointer',
            }}
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'sellerNote',
              });
            }}
          >
            {value}
          </Typography.Text>
        ) : (
          <Button
            size="small"
            onClick={() => {
              setEditValidator({
                data: record,
                rowIndex: index,
                colIndex: 'sellerNote',
              });
            }}
            icon={<EditOutlined />}
          />
        ),
    },
    {
      title: setFont(
        t('general.reviewStatus.title'),
        fontSize,
        `${t('general.reviewStatus.title')}`
      ),
      key: 'reviewStatus',
      dataIndex: 'reviewStatus',
      width: 120,
      render: (text: string) => setFont(`${t(REVIEW_STATUS[text])}`, fontSize),
    },
    {
      title: setFont(t('goods.goodsListColumns.addTime'), fontSize),
      key: 'addTime',
      dataIndex: 'addTime',
      width: 120,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('goods.goodsListColumns.lastUpdate'), fontSize),
      key: 'lastUpdate',
      dataIndex: 'lastUpdate',
      width: 120,
      sorter: true,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: GoodData) => (
        <Space>
          <Button
            type="link"
            size="small"
            style={{ padding: 0, fontSize: fontSize }}
            onClick={() =>
              addTab(
                '',
                `${dashboardRoute.goods.detail}?good_id=${record.goodsId}`
              )
            }
            disabled={!hasPermission(actionPermissions.goodGroup.goodView)}
          >
            {t('actionsColumn.view')}
          </Button>
          <Dropdown
            // getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
            trigger={['click']}
            onVisibleChange={(flag) => {
              if (morePopoverVis[record.goodsId])
                setMorePopoverVis({ [record.goodsId]: flag });
            }}
            visible={morePopoverVis[record.goodsId]}
            overlay={
              <Menu
                onClick={(e) => {
                  if (e.key !== 'deleteArchive') {
                    setMorePopoverVis({ [record.goodsId]: false });
                  }
                }}
              >
                {hasPermission(actionPermissions.goodGroup.goodView) && (
                  <Menu.Item
                    key="goodsLog"
                    style={{ fontSize: fontSize }}
                    onClick={() =>
                      addTab(
                        '',
                        `${dashboardRoute.goods.log}?good_id=${record.goodsId}`
                      )
                    }
                  >
                    {t('goods.actionsColumn.goodLog')}
                  </Menu.Item>
                )}
                {hasPermission(actionPermissions.goodGroup.goodView) && (
                  <Menu.Item
                    key="inventoryLog"
                    style={{ fontSize: fontSize }}
                    onClick={() =>
                      addTab(
                        '',
                        `${dashboardRoute.goods.inventoryLogsGoodsList}?good_id=${record.goodsId}`
                      )
                    }
                  >
                    {t('goods.actionsColumn.inventoryLog')}
                  </Menu.Item>
                )}
                {hasPermission(actionPermissions.goodGroup.goodComments) && (
                  <Menu.Item
                    key="goodsComment"
                    style={{ fontSize: fontSize }}
                    onClick={() =>
                      addTab(
                        '',
                        `${dashboardRoute.goods.comments}?good_id=${record.goodsId}`
                      )
                    }
                  >
                    {t('goods.actionsColumn.goodComment')}
                  </Menu.Item>
                )}
                <Menu.Item
                  key="restoreCopy"
                  style={{ fontSize: fontSize }}
                  onClick={() => {
                    if (
                      record.isDelete &&
                      hasPermission(actionPermissions.goodGroup.goodManage)
                    ) {
                      handleGoodsDAR([record.goodsId], 'restore');
                    } else {
                      setEditingGood(record);
                      setShowGoodModal(true);
                    }
                  }}
                >
                  {record.isDelete
                    ? t('goods.actionsColumn.restore')
                    : t('goods.actionsColumn.copyGood')}
                </Menu.Item>
                {isSeller && (
                  <Menu.Item
                    key="review"
                    style={{ fontSize: fontSize }}
                    onClick={() => {
                      setEditingGood(record);
                      setShowReviewModal(true);
                    }}
                  >
                    {t('goods.actionsColumn.review')}
                  </Menu.Item>
                )}
                {hasPermission(actionPermissions.goodGroup.goodDropRestore) && (
                  <Menu.Item key="deleteArchive" style={{ fontSize: fontSize }}>
                    <Popconfirm
                      title={t('actionsColumn.deleteWarning')}
                      onConfirm={() => {
                        record.isDelete
                          ? handleGoodsDAR(record.goodsId, 'delete')
                          : handleGoodsDAR([record.goodsId], 'trash');
                      }}
                      okText={t('actionsColumn.confirmation.yes')}
                      cancelText={t('actionsColumn.confirmation.no')}
                      placement="leftBottom"
                    >
                      <Typography.Text type="danger">
                        {record.isDelete
                          ? t('goods.actionsColumn.delete')
                          : t('goods.actionsColumn.archive')}
                      </Typography.Text>
                    </Popconfirm>
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ padding: 0, fontSize: fontSize }}
              onClick={() => setMorePopoverVis({ [record.goodsId]: true })}
            >
              {t('actionsColumn.more')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // Handle  Delete/Archive/Restore Goods
  const handleGoodsDAR = async (goodsId: any, mode: string) => {
    try {
      const response = await postDataWithAuthToken(
        `goods/${mode === 'delete' ? mode : `${mode}_batch`}`,
        {
          goodsId: mode === 'delete' ? goodsId : undefined,
          goodsIds:
            mode === 'restore' || mode === 'trash' ? goodsId : undefined,
        }
      );
      if (response && response.goodStatus) {
        getGoodsData();
        alertMessage('success', t(`goods.alerts.mode.${mode}`));
      } else
        alertMessage(
          'error',
          response?.msg || t('general.noResponse'),
          response?.data || undefined
        );
    } catch (err) {
      console.log(err);
    }
  };

  const updateGood = (key: string, value: any, good: GoodData) => {
    postDataWithAuthToken('goods/edit', {
      [key]: value,
      goodsId: good.goodsId,
      ...(key === 'goodsWeight' ? value : {}),
      sellerNote:
        key === 'sellerNote' && value !== undefined ? value : undefined,
      expireDate: key === 'expireDate' ? value || '' : undefined,
      goodsNumberRule:
        key === 'goodsNumberRule'
          ? value.sign
            ? value
            : { sign: null }
          : undefined,
    })
      .then((response) => {
        if (response) {
          if (response.goodStatus) {
            getGoodsData(false);
            alertMessage('success', t('goods.alerts.goodsEdited'));
            setEditValidator(undefined);
            setGoodNumberRuleValues(undefined);
            setWeightValues(undefined);
            setIsEmptyInput(false);

            // 有促销活动提示
            if(response.data && response.data.favActivityList){
              Modal.warning({
                width: 800,
                title: t('goods.add/editGood.goodActivityTitle'),
                content: (
                  <FavActivitySection
                    favActivityList={response.data.favActivityList}
                  />
                ),
                okText: t('general.ok'),
                maskClosable: false,
              });
            }
          } else
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get Params from form (get/export goods list params)
  const getFormParams = useCallback(
    (byCategory?: boolean) => {
      return {
        navType: navType || undefined,
        keyword: keyword || undefined,
        page: page,
        size: pageSize,
        isSeller: isSeller,
        sellerId: formRef.current ? form.getFieldValue('sellerId') : undefined,
        catId: formRef.current
          ? form.getFieldValue('catId')
            ? form.getFieldValue('catId').at(-1)
            : undefined
          : undefined,
        eventTag: formRef.current
          ? form.getFieldValue('eventTag') !== 'all'
            ? form.getFieldValue('eventTag')
            : undefined
          : undefined,
        brandId: formRef.current ? form.getFieldValue('brandId') : undefined,
        supplierId:
          formRef.current && form.getFieldValue('supplierId')
            ? form.getFieldValue('supplierId')
            : undefined,
        departmentId: formRef.current
          ? form.getFieldValue('departmentId')
          : undefined,
        isOnSale: formRef.current
          ? form.getFieldValue('isOnSale') !== 'all'
            ? form.getFieldValue('isOnSale')
            : undefined
          : undefined,
          isAloneSale: formRef.current
          ? form.getFieldValue('isAloneSale') !== 'all'  
            ? !form.getFieldValue('isAloneSale')  // 是否为配件
            : undefined
          : undefined,
        isWeightGoods: formRef.current
          ? form.getFieldValue('isWeightGoods') !== 'all'
            ? form.getFieldValue('isWeightGoods')
            : undefined
          : undefined,
        isMember: formRef.current
          ? form.getFieldValue('isMember') !== 'all'
            ? form.getFieldValue('isMember')
            : undefined
          : undefined,
        hasMemberPrice: formRef.current
          ? form.getFieldValue('hasMemberPrice') !== 'all'
            ? form.getFieldValue('hasMemberPrice')
            : undefined
          : undefined,
        sortOrder:
          sortOrder === 'ascend'
            ? 'ASC'
            : sortOrder === 'descend'
            ? 'DESC'
            : undefined,
        sortValue: (sortOrder && sortValue) || undefined,
        reviewStatus: formRef.current
          ? form.getFieldValue('reviewStatus') === 'all'
            ? undefined
            : form.getFieldValue('reviewStatus')
          : undefined,
        goodsTag: formRef.current
          ? form.getFieldValue('goodsTag') === 'all'
            ? undefined
            : form.getFieldValue('goodsTag')
          : undefined,
        sellerNote:
          formRef.current && form.getFieldValue('sellerNote')
            ? form.getFieldValue('sellerNote').toString()
            : undefined,
        goodsName:
          formRef.current && form.getFieldValue('goodsName')
            ? form.getFieldValue('goodsName').toString()
            : undefined,
        goodsUnit:
          formRef.current && form.getFieldValue('goodsUnit')
            ? form.getFieldValue('goodsUnit').toString()
            : undefined,
        goodsSn:
          formRef.current && form.getFieldValue('goodsSn')
            ? form.getFieldValue('goodsSn').toString()
            : undefined,
        barcode:
          formRef.current && form.getFieldValue('barcode')
            ? form.getFieldValue('barcode').toString()
            : undefined,
        supplierSku:
          formRef.current && form.getFieldValue('supplierSku')
            ? form.getFieldValue('supplierSku').toString()
            : undefined,

        // used only when export category goods list
        byCategory: byCategory !== undefined ? byCategory : undefined,
      };
    },
    [keyword, page, pageSize, navType, isSeller, form, sortOrder, sortValue]
  );

  // Fetch goods List
  const getGoodsData = useCallback(
    (scrollToTop: boolean = true) => {
      if (isSubscribed.current) setIsLoading(true);
      getDataWithAuthToken('goods/list', {
        params: getFormParams(),
      })
        .then((response) => {
          if (response) {
            if (response.goodStatus) {
              if (isSubscribed.current) {
                setGoodsData(response.data.list);
                setTotal(response.data.totalItem);

                // Scroll to top when data changes
                if (scrollToTop) tableScrollToTop();
              }
            } else if (response.returnCode === 403) {
              if (isSubscribed.current) setFourZeroThree(true);
            } else {
              isSubscribed.current && setFiveHundred(true);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          } else isSubscribed.current && setFiveHundred(true);
          if (isSubscribed.current) setIsLoading(false);
        })
        .catch((err) => {
          if (isSubscribed.current) setIsLoading(false);
          console.log(err);
        });
    },
    [t, getFormParams]
  );

  // Fetch good enums
  const getGoodEnums = useCallback(() => {
    getDataWithAuthToken('goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGoodsEnum(response.data);
          } else {
            alertMessage(
              'error',
              response?.msg || t('general.noResponse'),
              response?.data || undefined
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [t]);

  // Fetch goods tag list
  const getGoodsTags = () => {
    getDataWithAuthToken('goods/enum_list')
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setGoodsTags(response.data.goodsTag);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const advancedSearch = (
    <Drawer
      visible={showDrawer}
      closable={true}
      onClose={() => setShowDrawer(false)}
      placement="right"
      headerStyle={{ padding: '10px 3px 10px 3px' }}
      width={screens.sm ? undefined : 300}
      bodyStyle={{ padding: 10 }}
      title={t('goods.goodsListColumns.advancedSearch.title')}
    >
      <Form
        layout="vertical"
        form={form}
        ref={formRef}
        initialValues={{
          catId: [],
          eventTag: 'all',
          isOnSale: 'all',
          isWeightGoods: 'all',
          isMember: 'all',
          isAloneSale: 'all',
          hasMemberPrice: 'all',
          reviewStatus: 'all',
          goodsTag: 'all',
        }}
      >
        <Space style={{ marginBottom: 12 }}>
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => {
              setKeyword('');
              if (page !== 1) setPage(1);
              else {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => getGoodsData(), GENERAL_TIMEOUT)
                );
              }
            }}
          >
            {t('goods.goodsListColumns.advancedSearch.search')}
          </Button>
          <Button
            disabled={Object.values(searchAdvance).every(
              (value) => typeof value !== 'number' && !value
            )}
            onClick={() => {
              form.resetFields();
              setSearchAdvance({});
              if (page !== 1) setPage(1);
              else getGoodsData();
            }}
          >
            {t('goods.goodsListColumns.advancedSearch.reset')}
          </Button>
        </Space>
        {isSeller && (
          <Form.Item
            label={t('goods.goodsListColumns.advancedSearch.sellerId')}
            name="sellerId"
            style={{ marginBottom: 12 }}
          >
            <SellersDropdown
              onChange={(value) =>
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  sellerId: value,
                }))
              }
            />
          </Form.Item>
        )}
        <Form.Item
          label={t('goods.add/editGood.goodsName')}
          name="goodsName"
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setSearchAdvance((prev) => ({
                ...prev,
                goodsName: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.add/editGood.goodsUnit')}
          name="goodsUnit"
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setSearchAdvance((prev) => ({
                ...prev,
                goodsUnit: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.add/editGood.goodsSn')}
          name="goodsSn"
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setSearchAdvance((prev) => ({
                ...prev,
                goodsSn: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.add/editGood.barcode')}
          name="barcode"
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setSearchAdvance((prev) => ({
                ...prev,
                barcode: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.add/editGood.supplierSku')}
          name="supplierSku"
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setSearchAdvance((prev) => ({
                ...prev,
                supplierSku: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.add/editGood.sellerNote')}
          name="sellerNote"
          style={{ marginBottom: 12 }}
        >
          <Input
            allowClear
            onChange={(e) => {
              setSearchAdvance((prev) => ({
                ...prev,
                sellerNote: e.target.value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.goodsListColumns.advancedSearch.brandId')}
          name="brandId"
          style={{ marginBottom: 12 }}
        >
          <BrandDropdown
            onChange={(value) =>
              setSearchAdvance((prev: any) => ({ ...prev, brandId: value }))
            }
          />
        </Form.Item>
        <Form.Item
          label={t('goods.add/editSupplier.title')}
          name="supplierId"
          style={{ marginBottom: 12 }}
        >
          <SupplierDropdown
            onChange={(value) => {
              setSearchAdvance((prev: any) => ({
                ...prev,
                supplierId: value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.goodsListColumns.advancedSearch.departmentId')}
          name="departmentId"
          style={{ marginBottom: 12 }}
        >
          <DepartmentDropdown
            onChange={(value) => {
              setSearchAdvance((prev) => ({
                ...prev,
                departmentId: value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.goodsListColumns.category')}
          name="catId"
          style={{ marginBottom: 12 }}
        >
          <CategoryDropdown
            onFocusFetch={true}
            columnWidth={100}
            onChange={(value) => {
              setSearchAdvance((prev: any) => ({
                ...prev,
                catId: value ? value.at(-1) : value,
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={t('goods.goodsListColumns.isOnSale')}
          name="isOnSale"
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('general.pleaseSelect')}
            showSearch={false}
            onChange={(value) => {
              setSearchAdvance((prev: any) => ({
                ...prev,
                isOnSale:
                  value === 'all' ? undefined : Boolean(value).toString(),
              }));
            }}
          >
            <Select.Option key="all" value="all">
              {t(`goods.goodsListColumns.all`)}
            </Select.Option>
            <Select.Option key="true" value={true}>
              {t(`actionsColumn.confirmation.yes`)}
            </Select.Option>
            <Select.Option key="false" value={false}>
              {t(`actionsColumn.confirmation.no`)}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('goods.goodsListColumns.isNotAloneSale')}
          name="isAloneSale"
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('general.pleaseSelect')}
            showSearch={false}
            onChange={(value) => {
              setSearchAdvance((prev: any) => ({
                ...prev,
                isAloneSale:
                  value === 'all' ? undefined : Boolean(value).toString(),
              }));
            }}
          >
            <Select.Option key="all" value="all">
              {t(`goods.goodsListColumns.all`)}
            </Select.Option>
            <Select.Option key="true" value={true}>
              {t(`actionsColumn.confirmation.yes`)}
            </Select.Option>
            <Select.Option key="false" value={false}>
              {t(`actionsColumn.confirmation.no`)}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('goods.goodsListColumns.isWeightGoods')}
          name="isWeightGoods"
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('general.pleaseSelect')}
            showSearch={false}
            onChange={(value) => {
              setSearchAdvance((prev: any) => ({
                ...prev,
                isWeightGoods:
                  value === 'all' ? undefined : Boolean(value).toString(),
              }));
            }}
          >
            <Select.Option key="all" value="all">
              {t(`goods.goodsListColumns.all`)}
            </Select.Option>
            <Select.Option key="true" value={true}>
              {t(`actionsColumn.confirmation.yes`)}
            </Select.Option>
            <Select.Option key="false" value={false}>
              {t(`actionsColumn.confirmation.no`)}
            </Select.Option>
          </Select>
        </Form.Item>
        {!getDashboardStyle().isSellersCoAppType && (
          <Form.Item
            label={t('goods.goodsListColumns.isMember')}
            name="isMember"
            style={{ marginBottom: 12 }}
          >
            <Select
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) => {
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  isMember:
                    value === 'all' ? undefined : Boolean(value).toString(),
                }));
              }}
            >
              <Select.Option key="all" value="all">
                {t(`goods.goodsListColumns.all`)}
              </Select.Option>
              <Select.Option key="true" value={true}>
                {t(`actionsColumn.confirmation.yes`)}
              </Select.Option>
              <Select.Option key="false" value={false}>
                {t(`actionsColumn.confirmation.no`)}
              </Select.Option>
            </Select>
          </Form.Item>
        )}
        {!getDashboardStyle().isSellersCoAppType && (
          <Form.Item
            label={t('goods.goodsListColumns.hasMemberPrice')}
            name="hasMemberPrice"
            style={{ marginBottom: 12 }}
          >
            <Select
              placeholder={t('general.pleaseSelect')}
              showSearch={false}
              onChange={(value) => {
                setSearchAdvance((prev: any) => ({
                  ...prev,
                  hasMemberPrice:
                    value === 'all' ? undefined : Boolean(value).toString(),
                }));
              }}
            >
              <Select.Option key="all" value="all">
                {t(`goods.goodsListColumns.all`)}
              </Select.Option>
              <Select.Option key="true" value={true}>
                {t(`actionsColumn.confirmation.yes`)}
              </Select.Option>
              <Select.Option key="false" value={false}>
                {t(`actionsColumn.confirmation.no`)}
              </Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={t('goods.add/editGood.goodsTag')}
          name="goodsTag"
          style={{ marginBottom: 12 }}
        >
          <Select
            defaultActiveFirstOption
            placeholder={t('general.pleaseSelect')}
            showSearch={false}
            onChange={(value) =>
              setSearchAdvance((prev: any) => ({
                ...prev,
                goodsTag: value === 'all' ? '' : value,
              }))
            }
            onFocus={() => {
              if (!goodsTags.length) getGoodsTags();
            }}
          >
            <Select.Option key="all" value="all">
              {t(`goods.goodsListColumns.all`)}
            </Select.Option>
            {goodsTags.map((tag) => (
              <Select.Option key={tag.code} value={tag.code}>
                {tag.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('goods.goodsListColumns.eventTag')}
          name="eventTag"
          style={{ marginBottom: 12 }}
        >
          <Select
            defaultActiveFirstOption
            placeholder={t('general.pleaseSelect')}
            showSearch={false}
            onChange={(value) =>
              setSearchAdvance((prev: any) => ({
                ...prev,
                eventTag: value === 'all' ? '' : value,
              }))
            }
          >
            <Select.Option key="all" value="all">
              {t(`goods.goodsListColumns.all`)}
            </Select.Option>
            {goodsEnum &&
              goodsEnum.goodsEventTag.map((tag) => (
                <Select.Option key={tag.code} value={tag.code}>
                  {tag.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('goods.goodsListColumns.advancedSearch.reviewStatus')}
          name="reviewStatus"
          style={{ marginBottom: 12 }}
        >
          <Select
            placeholder={t('general.pleaseSelect')}
            showSearch={false}
            onChange={(value) =>
              setSearchAdvance((prev: any) => ({
                ...prev,
                reviewStatus: value === 'all' ? undefined : value,
              }))
            }
          >
            <Select.Option key="all" value="all">
              {t(`goods.goodsListColumns.all`)}
            </Select.Option>
            {Object.keys(REVIEW_STATUS).map((type) => {
              return (
                <Select.Option key={type} value={type}>
                  {t(REVIEW_STATUS[type])}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Space>
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => {
              setKeyword('');
              if (page !== 1) setPage(1);
              else {
                if (typingTimeout) clearTimeout(typingTimeout);
                setTypingTimeout(
                  setTimeout(() => getGoodsData(), GENERAL_TIMEOUT)
                );
              }
            }}
          >
            {t('goods.goodsListColumns.advancedSearch.search')}
          </Button>
          <Button
            disabled={Object.values(searchAdvance).every(
              (value) => typeof value !== 'number' && !value
            )}
            onClick={() => {
              form.resetFields();
              setSearchAdvance({});
              if (page !== 1) setPage(1);
              else getGoodsData();
            }}
          >
            {t('goods.goodsListColumns.advancedSearch.reset')}
          </Button>
        </Space>
      </Form>
    </Drawer>
  );

  /**
   * @param pagination Current pagination state
   * @param filters    Current filter state
   * @param sorter     Current sorter state
   * @param extra      Current data source and action
   */
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<GoodData> | SorterResult<GoodData>[],
    extra: {
      currentDataSource: GoodData[];
      action: 'paginate' | 'sort' | 'filter';
    }
  ) => {
    if (extra.action === 'sort' && !Array.isArray(sorter)) {
      if (
        sorter.field === 'goodsId' ||
        sorter.field === 'salesVolume' ||
        sorter.field === 'sortOrder' ||
        sorter.field === 'goodsNumber' ||
        sorter.field === 'shopPrice|isMember|memberPrice' ||
        sorter.field === 'goodsName' ||
        sorter.field === 'expireDate' ||
        sorter.field === 'lastUpdate'
      ) {
        setSortOrder(
          sorter.order === 'ascend'
            ? 'ascend'
            : sorter.order === 'descend'
            ? 'descend'
            : undefined
        );
        setSortValue(
          sorter.field === 'salesVolume'
            ? 'SALES_VOLUME'
            : sorter.field === 'sortOrder'
            ? 'SORT_ORDER'
            : sorter.field === 'goodsId'
            ? 'GOODS_ID'
            : sorter.field === 'goodsNumber'
            ? 'GOODS_NUMBER'
            : sorter.field === 'shopPrice|isMember|memberPrice'
            ? 'SHOP_PRICE'
            : sorter.field === 'goodsName'
            ? 'GOODS_NAME'
            : sorter.field === 'expireDate'
            ? 'EXPIRE_DATE_ORDER'
            : sorter.field === 'lastUpdate'
            ? 'LAST_UPDATE_ORDER'
            : ''
        );
      } else {
      }
    }
  };

  useEffect(() => {
    getGoodsData();
  }, [getGoodsData]);

  useEffect(() => {
    if (hasPermission(actionPermissions.goodGroup.goodView)) getGoodEnums();
  }, [getGoodEnums]);

  const batchArchive = () => {
    if (isSubscribed.current) setIsLoading(true);
    postDataWithAuthToken('goods/trash_batch', {
      goodsIds: selectedRowKeys,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getGoodsData();
          alertMessage('success', t('goods.alerts.userDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setIsLoading(false);
      });
  };

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <Card>
          <Space>
            <Typography.Title level={3} style={{ fontWeight: 500 }}>
              {t('goods.goodsList')}
            </Typography.Title>
            {getDashboardStyle().isSellerSwitch && (
              <Switch
                loading={isLoading}
                checkedChildren={t('goods.goodsListColumns.seller')}
                unCheckedChildren={t('goods.goodsListColumns.self')}
                style={{ marginBottom: 12 }}
                onChange={(checked) => {
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => {
                      setIsSeller(checked);
                      setPage(1);
                    }, EXTENDED_TIMEOUT)
                  );
                  if (!checked) {
                    formRef.current && form.resetFields(['sellerId']);
                    setSearchAdvance((prev: any) => ({
                      ...prev,
                      sellerId: undefined,
                    }));
                  }
                }}
              />
            )}
          </Space>
          <Tabs
            onTabClick={(key) => {
              if (key !== navType) {
                setSelectedRowKeys([]);
                setSelectedRows([]);
                setNavType(key);
                setPage(1);
              }
            }}
          >
            {goodsEnum &&
              goodsEnum.goodsListNav.length &&
              goodsEnum.goodsListNav.map((type) => (
                <TabPane key={type.code} tab={type.description} />
              ))}
          </Tabs>
          <TableToolbar
            leftElement={
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditingGood(undefined);
                  setShowGoodModal(true);
                }}
                disabled={
                  isLoading ||
                  !hasPermission(actionPermissions.goodGroup.goodManage)
                }
              >
                {isSeller
                  ? t('goods.add/editGood.addSellerGoodTitle')
                  : t('goods.add/editGood.addTitle')}
              </Button>
            }
            tableSize={tableSize}
            setTableSize={setTableSize}
            fontSize={fontSize}
            setFontSize={setFontSize}
            refresh={() => getGoodsData()}
            totalItems={total}
            rows={goodsData.map((good) => ({
              ...good,
              brandName: good.brand ? good.brand.brandName : '',
              shopName: good.seller ? good.seller.shopName : '',
              supplierName: good.supplier ? good.supplier.supplierName : '',
              reviewStatus: good.reviewStatus ? good.reviewStatus.code : '',
              isWeightGoods: good.isWeightGoods,
              goodsWeight: good.goodsWeight ? good.goodsWeight : '',
              estimateShopPrice: good.estimateShopPrice
                ? good.estimateShopPrice
                : '',
              goodsNumberRule: good.goodsNumberRule
                ? `${good.goodsNumberRule.description} ${good.goodsNumberRule.number}`
                : '',
            }))}
            columns={columns}
            columnKeys={columnKeys}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            searchPlaceholder={t(
              'searchPlaceholders.searchNameSnBarcodeKeywords'
            )}
            search={(keyword) => {
              setKeyword(keyword);
              setPage(1);
            }}
            advancedSearch={
              <Badge
                count={Object.keys(searchAdvance).reduce((accumulator, obj) => {
                  if (
                    searchAdvance[obj as keyof typeof searchAdvance] &&
                    searchAdvance[obj as keyof typeof searchAdvance] !==
                      'default'
                  ) {
                    return accumulator + 1;
                  }

                  return accumulator;
                }, 0)}
              >
                <Button
                  icon={<FilterOutlined />}
                  onClick={() => setShowDrawer(showDrawer ? false : true)}
                >
                  {t('goods.goodsListColumns.advancedSearch.title')}
                </Button>
                {advancedSearch}
              </Badge>
            }
            exportConfig={
              hasPermission(actionPermissions.goodGroup.goodView)
                ? {
                    fileName: 'GOODS_LIST',
                    url: getExportUrl(getFormParams()),
                    otherExports: [
                      {
                        title: t('goods.actionsColumn.exportCategoryGoods'),
                        url: getExportUrl(getFormParams(true)),
                      },
                    ],
                  }
                : undefined
            }
          />
          <Table<GoodData>
            dataSource={goodsData}
            columns={columns.filter((x) =>
              selectedColumns.includes(x.key?.toString() ?? '')
            )}
            loading={isLoading}
            size={tableSize}
            components={vt}
            scroll={{ y: 600, x: 1200 }}
            rowKey={(good) => good.goodsId}
            rowSelection={{
              selectedRowKeys,
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: GoodData[]
              ) => {
                setSelectedRowKeys(selectedRowKeys);
                setSelectedRows(selectedRows);
              },
            }}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                t('general.paginationTotal', {
                  start: range[0],
                  end: range[1],
                  total: total,
                }),
              size: 'small',
              defaultPageSize: pageSize,
              onChange: (page, pSize) => {
                setPage(page);
                setPageSize(pSize || pageSize);
                setSelectedRowKeys([]);
              },
              total: total,
              current: page,
            }}
            onChange={onTableChange}
          />
          {!!selectedRowKeys.length && (
            <TableFooterToolbar
              goodInfo={{ navType: navType }}
              selectedRowKeys={selectedRowKeys}
              selectedRows={selectedRows.map((good) => ({
                ...good,
                brandName: good.brand ? good.brand.brandName : '',
                shopName: good.seller ? good.seller.shopName : '',
                reviewStatus: good.reviewStatus ? good.reviewStatus.code : '',
                isWeightGoods: good.isWeightGoods,
                goodsWeight: good.goodsWeight ? good.goodsWeight : '',
                estimateShopPrice: good.estimateShopPrice
                  ? good.estimateShopPrice
                  : '',
                goodsNumberRule: good.goodsNumberRule
                  ? `${good.goodsNumberRule.description} ${good.goodsNumberRule.number}`
                  : '',
              }))}
              setSelectedRowKeys={setSelectedRowKeys}
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              typeSpecificActions="goods"
              general={
                hasPermission(actionPermissions.goodGroup.goodManage)
                  ? { advance: batchAdvance, setAdvance: setBatchAdvance }
                  : undefined
              }
              funct={{
                refresh: () => getGoodsData(),
                exportConfig: hasPermission(
                  actionPermissions.goodGroup.goodView
                )
                  ? { fileName: 'GOODS_LIST' }
                  : undefined,
                deleteFunc: hasPermission(
                  actionPermissions.goodGroup.goodManage
                )
                  ? batchArchive
                  : undefined,
              }}
            />
          )}
        </Card>
      )}
      <GoodModal
        goodEnums={goodsEnum}
        isSeller={isSeller}
        visible={showGoodModal}
        setVisible={setShowGoodModal}
        callBack={getGoodsData}
        copyGood={editingGood}
        setCopyGood={setEditingGood}
      />
      <GoodReviewModal
        visible={showReviewModal}
        setVisible={setShowReviewModal}
        goodInfo={editingGood}
        refresh={() => getGoodsData(false)}
      />
      <GoodStockModal
        visible={showStockModal}
        setVisible={setShowStockModal}
        goodInfo={editingGood}
        refresh={() => getGoodsData(false)}
      />
    </Container>
  );
};

export default GoodsList;
