import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Form,
  Grid,
  Popconfirm,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVT } from 'virtualizedtableforantd4';
import Container from '../../components/Container';
import FiveHundred from '../../components/FiveHundred';
import FourZeroThree from '../../components/FourZeroThree';
import FavActivityModal from '../../components/activity/FavActivityModal';
import SellersDropdown from '../../components/sellers/SellersDropdown';
import TableFooterToolbar from '../../components/table/TableFooterToolbar';
import TableToolbar from '../../components/table/TableToolbar';
import { actionPermissions } from '../../constants/actionPermissions';
import { REVIEW_STATUS } from '../../constants/generalConstants';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_SIZE_TYPE,
  EXTENDED_TIMEOUT,
  GENERAL_TIMEOUT,
} from '../../constants/systemConstants';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { FavActivityData, FavActivityEnum, FontSizeType } from '../../types';
import { alertMessage } from '../../utils/alertMessage';
import {
  getDataWithAuthToken,
  postDataWithAuthToken,
} from '../../utils/axiosRequest';
import { compare, setFont } from '../../utils/colComponents';
import getDashboardStyle from '../../utils/getDashboardStyle';
import { hasPermission } from '../../utils/hasPermission';
import { tableScrollToTop } from '../../utils/helperFunction';

/**
 * Display Fav Activity List Table
 * Can add, edit, or delete fav activities
 *
 * /dashboard/activity/favActivityList
 *
 */
const FavActivityList = () => {
  //General Components
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [tableSize, setTableSize] = useState<SizeType>(DEFAULT_SIZE_TYPE);
  const [fontSize, setFontSize] = useState<FontSizeType>(DEFAULT_FONT_SIZE);
  const [advance, setAdvance] = useState<{
    [key: string]: any;
  }>({});
  const [fourZeroThree, setFourZeroThree] = useState(false);
  const [fiveHundred, setFiveHundred] = useState(false);
  const { useBreakpoint } = Grid;
  const isSubscribed = useRef(true);
  const screens = useBreakpoint();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSeller, setIsSeller] = useState(false);
  //Data Components
  const [favActivityData, setFavActivityData] = useState<
    Array<FavActivityData>
  >([]);
  const [favActivityEnum, setFavActivityEnum] = useState<FavActivityEnum>();
  const [selectedFavActivity, setSelectedFavActivity] =
    useState<FavActivityData>();
  //Table Components
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useLocalStorage('pageSize', '10');
  const [keyword, setKeyword] = useState('');
  const [total, setTotal] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const columnKeys = [
    'actId',
    'seller',
    'actType',
    'actName',
    'minAmount',
    'maxAmount',
    'sortOrder',
    'activityStatus',
    'reviewStatus',
    'action',
  ];
  const [selectedColumns, setSelectedColumns] = useState(columnKeys);

  const columns: ColumnsType<FavActivityData> = [
    {
      title: setFont(t('activity.favActivityColumnList.actId'), fontSize),
      dataIndex: 'actId',
      key: 'actId',
      width: 70,
      fixed: screens.lg ? 'left' : undefined,
      sorter: (a: FavActivityData, b: FavActivityData) =>
        compare(a.actId, b.actId),
      render: (text: string, record: FavActivityData) => (
        <Button
          type="link"
          style={{ padding: 0, fontSize: fontSize }}
          disabled={!hasPermission(actionPermissions.activityGroup.favourable)}
          onClick={() => {
            setSelectedFavActivity(record);
            setModalVisible(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: setFont(t('activity.favActivityColumnList.seller'), fontSize),
      dataIndex: 'seller',
      key: 'seller',
      width: 120,
      render: (text: string, record: FavActivityData) =>
        setFont(record.seller ? record.seller.shopName : '', fontSize),
    },
    {
      title: setFont(
        t('activity.favActivityColumnList.actType.title'),
        fontSize
      ),
      dataIndex: 'actType',
      key: 'actType',
      width: 120,
      render: (text: string) =>
        setFont(t(`activity.favActivityColumnList.actType.${text}`), fontSize),
    },
    {
      title: setFont(t('activity.favActivityColumnList.actName'), fontSize),
      dataIndex: 'actName',
      key: 'actName',
      width: 160,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.favActivityColumnList.minAmount'), fontSize),
      dataIndex: 'minAmount',
      key: 'minAmount',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('activity.favActivityColumnList.maxAmount'), fontSize),
      dataIndex: 'maxAmount',
      key: 'maxAmount',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(
        t('activity.favActivityColumnList.activityStatus.title'),
        fontSize
      ),
      dataIndex: 'activityStatus',
      key: 'activityStatus',
      width: 120,
      render: (text: string, record: FavActivityData) =>
        record.activityStatus && (
          <Tag
            style={{ fontSize: fontSize }}
            color={record.activityStatus.tagColor}
          >
            {record.activityStatus.description}
          </Tag>
        ),
    },
    {
      title: setFont(
        t('activity.favActivityColumnList.reviewStatus'),
        fontSize
      ),
      dataIndex: 'reviewStatus',
      key: 'reviewStatus',
      width: 120,
      render: (text: string, record: FavActivityData) =>
        record.reviewStatus && (
          <Tag
            style={{ fontSize: fontSize }}
            color={record.reviewStatus.tagColor}
          >
            {record.reviewStatus.description}
          </Tag>
        ),
    },
    {
      title: setFont(t('activity.favActivityColumnList.sortOrder'), fontSize),
      dataIndex: 'sortOrder',
      key: 'sortOrder',
      width: 100,
      render: (text: string) => setFont(text, fontSize),
    },
    {
      title: setFont(t('actionsColumn.title'), fontSize),
      width: 120,
      key: 'action',
      fixed: screens.lg ? 'right' : undefined,
      render: (record: FavActivityData) => (
        <Space>
          <Button
            type="link"
            style={{ padding: 0, fontSize: fontSize }}
            disabled={
              !hasPermission(actionPermissions.activityGroup.favourable)
            }
            onClick={() => {
              setSelectedFavActivity(record);
              setModalVisible(true);
            }}
          >
            {t('actionsColumn.edit')}
          </Button>
          <Popconfirm
            title={t('actionsColumn.deleteWarning')}
            onConfirm={() => {
              deleteFavAct(record.actId);
            }}
            okText={t('actionsColumn.confirmation.yes')}
            cancelText={t('actionsColumn.confirmation.no')}
            placement="topRight"
            disabled={
              !hasPermission(actionPermissions.activityGroup.favourable)
            }
          >
            <Button
              danger
              type="link"
              disabled={
                !hasPermission(actionPermissions.activityGroup.favourable)
              }
              style={{ padding: 0, fontSize: fontSize }}
            >
              {t('actionsColumn.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const deleteFavAct = (id: number) => {
    if (isSubscribed.current) setLoading(true);
    postDataWithAuthToken('fav_activity/delete', {
      actId: id,
    })
      .then((response) => {
        if (response && response.goodStatus) {
          getData();
          alertMessage('success', t('activity.alerts.favActivityDeleted'));
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  };

  // Sets isSubscribed to false if component becomes unmounted
  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  /**
   * Fetches Fav Activities
   */
  const getData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`fav_activity/list`, {
      params: {
        page: page,
        size: pageSize,
        keyword: keyword ? keyword : undefined,
        isSeller: isSeller,
        sellerId:
          formRef.current && form.getFieldValue('sellerId')
            ? form.getFieldValue('sellerId')
            : undefined,
        actType:
          formRef.current && form.getFieldValue('actType')
            ? form.getFieldValue('actType') === 'all'
              ? undefined
              : form.getFieldValue('actType')
            : undefined,
        reviewStatus:
          formRef.current && form.getFieldValue('reviewStatus')
            ? form.getFieldValue('reviewStatus') === 'all'
              ? undefined
              : form.getFieldValue('reviewStatus')
            : undefined,
      },
    })
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setFavActivityData(response.data.list);
            setTotal(response.data.totalItem);
            setSelectedRowKeys([]);

            // Scroll to top when data changes
            tableScrollToTop();
          }
        } else if (response && response.returnCode === 403) {
          if (isSubscribed.current) setFourZeroThree(true);
        } else {
          isSubscribed.current && setFiveHundred(true);
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t, page, pageSize, keyword, form, isSeller]);

  const getFavActivityEnumData = useCallback(() => {
    if (isSubscribed.current) setLoading(true);
    getDataWithAuthToken(`fav_activity/enum_list`)
      .then((response) => {
        if (response && response.goodStatus) {
          if (isSubscribed.current) {
            setFavActivityEnum(response.data);
          }
        } else {
          alertMessage(
            'error',
            response?.msg || t('general.noResponse'),
            response?.data || undefined
          );
        }
        if (isSubscribed.current) setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (isSubscribed.current) setLoading(false);
      });
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getFavActivityEnumData();
  }, [getFavActivityEnumData]);

  const advancedSearch = (
    <Popover
      overlayStyle={{ zIndex: 100 }}
      style={{ width: '200%' }}
      title={t('activity.favActivityColumnList.advancedSearch.title')}
      trigger="click"
      placement="bottomRight"
      content={
        <Form
          layout="vertical"
          form={form}
          ref={formRef}
          initialValues={{ actType: 'all', reviewStatus: 'all' }}
        >
          {isSeller && (
            <Form.Item
              label={t(
                'activity.favActivityColumnList.advancedSearch.sellerId'
              )}
              name="sellerId"
            >
              <SellersDropdown
                onChange={(value) =>
                  setAdvance((prev: any) => ({
                    ...prev,
                    sellerId: value,
                  }))
                }
              />
            </Form.Item>
          )}
          <Form.Item
            label={t('activity.favActivityColumnList.advancedSearch.actType')}
            name="actType"
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={false}
              placeholder={t('general.inputError.pleaseSelectOne')}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  actType: value === 'all' ? '' : value,
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t('general.all')}
              </Select.Option>
              {favActivityEnum?.actType.map((actType) => (
                <Select.Option key={actType.description} value={actType.code}>
                  {actType.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t(
              'activity.favActivityColumnList.advancedSearch.reviewStatus'
            )}
            name="reviewStatus"
          >
            <Select
              placeholder={t('general.inputError.pleaseSelectOne')}
              showSearch={false}
              onChange={(value) =>
                setAdvance((prev: any) => ({
                  ...prev,
                  reviewStatus: value === 'all' ? '' : value,
                }))
              }
            >
              <Select.Option key="all" value="all">
                {t('general.all')}
              </Select.Option>
              {Object.keys(REVIEW_STATUS).map((type) => {
                return (
                  <Select.Option key={type} value={type}>
                    {t(REVIEW_STATUS[type])}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                onClick={() => {
                  setKeyword('');
                  if (typingTimeout) clearTimeout(typingTimeout);
                  setTypingTimeout(
                    setTimeout(() => getData(), GENERAL_TIMEOUT)
                  );
                }}
              >
                {t('activity.favActivityColumnList.advancedSearch.search')}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  setAdvance({});
                  getData();
                }}
              >
                {t('activity.favActivityColumnList.advancedSearch.reset')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      }
    >
      <Badge
        count={Object.keys(advance).reduce((accumulator, obj) => {
          if (advance[obj as keyof typeof advance]) {
            return accumulator + 1;
          }

          return accumulator;
        }, 0)}
      >
        <Button icon={<FilterOutlined />}>
          {t('activity.favActivityColumnList.advancedSearch.title')}
        </Button>
      </Badge>
    </Popover>
  );

  return (
    <Container>
      {fourZeroThree ? (
        <Card>
          <FourZeroThree />
        </Card>
      ) : fiveHundred ? (
        <Card>
          <FiveHundred />
        </Card>
      ) : (
        <>
          <Card>
            <Space>
              <Typography.Title level={3} style={{ fontWeight: 500 }}>
                {t('activity.favActivityList')}
              </Typography.Title>
              {getDashboardStyle().isSellerSwitch && (
                <Switch
                  loading={loading}
                  checkedChildren={t('activity.favActivityColumnList.seller')}
                  unCheckedChildren={t('activity.favActivityColumnList.self')}
                  style={{ marginBottom: 12 }}
                  onChange={(checked) => {
                    if (typingTimeout) clearTimeout(typingTimeout);
                    setTypingTimeout(
                      setTimeout(() => {
                        setIsSeller(checked);
                        setPage(1);
                      }, EXTENDED_TIMEOUT)
                    );
                    if (!checked) {
                      formRef.current && form.resetFields(['sellerId']);
                      setAdvance((prev: any) => ({
                        ...prev,
                        sellerId: undefined,
                      }));
                    }
                  }}
                />
              )}
            </Space>
            <TableToolbar
              advancedSearch={advancedSearch}
              leftElement={
                <Button
                  icon={<PlusOutlined />}
                  disabled={
                    !hasPermission(actionPermissions.activityGroup.favourable)
                  }
                  onClick={() => {
                    setModalVisible(true);
                    setSelectedFavActivity(undefined);
                  }}
                >
                  {t('activity.add/EditFavActivity.addTitle')}
                </Button>
              }
              tableSize={tableSize}
              setTableSize={setTableSize}
              fontSize={fontSize}
              setFontSize={setFontSize}
              refresh={() => getData()}
              totalItems={total}
              columns={columns}
              columnKeys={columnKeys}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              search={(keyword) => {
                setKeyword(keyword);
                setPage(1);
              }}
              searchPlaceholder={t('searchPlaceholders.searchFavActivity')}
              rows={favActivityData.map((favActivity) => ({
                ...favActivity,
              }))}
              exportConfig={{ fileName: 'FAV_ACTIVITY_LIST' }}
            />
            <Table<FavActivityData>
              columns={columns.filter((x) =>
                selectedColumns.includes(x.key?.toString() ?? '')
              )}
              dataSource={favActivityData}
              components={vt}
              scroll={{ y: 600, x: 1200 }}
              size={tableSize}
              loading={loading}
              pagination={{
                total: total,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  t('general.paginationTotal', {
                    start: range[0],
                    end: range[1],
                    total: total,
                  }),
                size: 'small',
                defaultPageSize: pageSize,
                onChange: (page, pSize) => {
                  setPage(page);
                  setPageSize(pSize || pageSize);
                  setSelectedRowKeys([]);
                },
                current: page,
              }}
              rowKey={(fav) => fav.actId}
            />
            {!!selectedRowKeys.length && (
              <TableFooterToolbar
                selectedRowKeys={selectedRowKeys}
                selectedRows={[]}
                setSelectedRowKeys={setSelectedRowKeys}
                columns={columns.filter((x) =>
                  selectedColumns.includes(x.key?.toString() ?? '')
                )}
                funct={{ exportConfig: { fileName: 'FAV_ACTIVITY_LIST' } }}
              />
            )}
          </Card>
          <FavActivityModal
            visible={modalVisible}
            setVisible={setModalVisible}
            refresh={() => getData()}
            isSeller={isSeller}
            selectedFavActivity={selectedFavActivity}
            favActivityTypes={favActivityEnum?.actType}
          />
        </>
      )}
    </Container>
  );
};

export default FavActivityList;
